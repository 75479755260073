import { Oval } from "react-loader-spinner";
import FlowIcon from "../../assets/images/dashboard/flow.png";
import { getNumberUnit } from "../../utils";

export default function CoinStatsCard(props) {
  const { className, value, title, unit, convert, loading } = props;

  return (
    <div className={`coin-stats-card text-center m-4 ${className}`}>
      {
        loading ?
          <div className="flex justify-center">
            <Oval
              height={48}
              width={48}
              color="#ffffff"
              wrapperStyle={{ marginRight: "8px" }}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="transparent"
              strokeWidth={6}
              strokeWidthSecondary={6}
            />
          </div> :
          <h1 className="text-5xl xl:text-5xl mb-3">
            {unit}
            {convert ? getNumberUnit(value, 2) : value}
          </h1>
      }
      <p className="text-3xl xl:text-xl mb-3">{title}</p>
      <img src={FlowIcon} alt="Flow icon" />
    </div>
  );
}
