import { useEffect, useState, useContext } from "react";
import LinearCard from "../components/Card/LinearCard";
import tokenLogo from "../assets/images/common/logo.png";
import erc20FarmAbi from "../web3/abi/erc20FarmAbi";
import getContractsAddress from "../web3/contractsAddress";
import tokenAbi from "../web3/abi/tokenAbi.json";
import BN from "bn.js";
import StyledContentLoader from "styled-content-loader";
import { mainnetTokens } from "../constant/tokens";
import { web3ModalContext } from "../components/Web3ModalProvider";
import { notify, error } from "../utils";
import { BSCSCAN_EXPLORER, BSC_Mainnet, BSC_Testnet } from "../config";

export default function NFTStaking() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract, tokenPrice } = useContext(web3ModalContext);
  const [selectStake, setSelectStake] = useState(true);
  const [pendingReward, setPendingReward] = useState("0.00");
  const [myHolding, setMyHolding] = useState("0.00");
  const [totalValueLocked, setTotalValueLocked] = useState(0);
  const [earned, setEarned] = useState(0);
  const [stakeAmount, setStakeAmount] = useState(0);
  const [unStakeAmount, setUnStakeAmount] = useState(0);
  const [APR, setAPR] = useState(0);
  const [isApproved, setIsApproved] = useState(true);
  const [userBalance, setUserBalance] = useState(0);
  const [unStakeBalance, setUnStakeBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonIndex, setButtonIndex] = useState(0);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const getAllowance = async () => {
    const _poolAddress = getContractsAddress(web3Data.chainId).PoolAddress;
    const tokenContract = getContract(web3Data.chainId == BSC_Mainnet ? getContractsAddress(BSC_Mainnet).tokenAddress : getContractsAddress(BSC_Testnet).tokenAddress, tokenAbi);
    const _allowanceBig = await tokenContract.allowance(web3Data.account, _poolAddress);
    const _allowance = _allowanceBig / 10 ** process.env.REACT_APP_AETERNA_DECIMAL;
    return _allowance;
  };

  const stake = async () => {
    setIsButtonLoading(true);
    setButtonIndex(0);
    try {
      const _poolAddress = getContractsAddress(web3Data.chainId).PoolAddress;
      const tokenContract = getContract(web3Data.chainId == BSC_Mainnet ? getContractsAddress(BSC_Mainnet).tokenAddress : getContractsAddress(BSC_Testnet).tokenAddress, tokenAbi);
      const poolContract = getContract(_poolAddress, erc20FarmAbi);
      // console.log(poolContract, stakeAmount);
      // const bigAmount = new BN(stakeAmount.toString(), 10);
      // const bigDecimal = new BN((10 ** process.env.REACT_APP_AETERNA_DECIMAL).toString(), 10);
      const bigAmountDecimal = parseInt(stakeAmount * 10 ** process.env.REACT_APP_AETERNA_DECIMAL);
      let result;
      if (!isApproved) {
        result = await tokenContract.approve(_poolAddress, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
        await result.wait();
        setIsApproved(true);
      }
      else {
        const result = await poolContract.deposit("0", bigAmountDecimal.toString(), "0x0000000000000000000000000000000000000000");
        await result.wait();
        initStaking();
        notify({
          text: `Stake ${stakeAmount} Aeterna`,
          link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${result.hash}`,
        });
        await checkAllowance();
      }
    } catch (e) {
      console.log(e);
    }
    setIsButtonLoading(false);
  };

  const harvest = async () => {
    setIsButtonLoading(true);
    setButtonIndex(2);
    try {
      const _poolAddress = getContractsAddress(web3Data.chainId).PoolAddress;
      const poolContract = getContract(_poolAddress, erc20FarmAbi);
      const result = await poolContract.deposit("0", "0", "0x0000000000000000000000000000000000000000");
      const tx = await result.wait();
      notify({
        text: `Havest AETERNA TOKEN`,
      });
    } catch (e) {
      console.log(e);
    }
    setIsButtonLoading(false);
  };

  const unStake = async () => {
    setIsButtonLoading(true);
    setButtonIndex(1);
    try {
      if (parseFloat(unStakeBalance) < parseFloat(unStakeAmount.toFixed(4))) {
        error({
          text: "Unstake amount exceed!"
        });
      }
      else {
        const _poolAddress = getContractsAddress(web3Data.chainId).PoolAddress;
        const poolContract = getContract(_poolAddress, erc20FarmAbi);
        // const bigAmount = new BN(unStakeAmount.toString(), 10);
        // const bigDecimal = new BN((10 ** process.env.REACT_APP_AETERNA_DECIMAL).toString(), 10);
        // const bigAmountDecimal = bigAmount.mul(bigDecimal);
        const bigAmountDecimal = parseInt(unStakeAmount * 10 ** process.env.REACT_APP_AETERNA_DECIMAL);
        const result = await poolContract.withdraw(0, bigAmountDecimal.toString());
        await result.wait();
        notify({
          text: `Stake ${unStakeAmount} Aeterna`,
          link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${result.hash}`,
        });
      }
    } catch (e) {
      console.log(e);
    }
    setIsButtonLoading(false);
  };

  const checkAllowance = async () => {
    const allowance = await getAllowance();
    if (allowance < stakeAmount)
      setIsApproved(false);
    else
      setIsApproved(true);
  }

  const handleStakeAmount = async (e) => {
    setStakeAmount(e.target.value)
  }

  useEffect(() => {
    checkAllowance();
  }, [stakeAmount])

  const initStaking = async () => {
    try {
      setIsLoading(true);
      const _poolAddress = getContractsAddress(web3Data.chainId).PoolAddress;
      const poolContract = getContract(_poolAddress, erc20FarmAbi);
      const tokenContract = getContract(web3Data.chainId == BSC_Mainnet ? getContractsAddress(BSC_Mainnet).tokenAddress : getContractsAddress(BSC_Testnet).tokenAddress, tokenAbi);
      const balance = await tokenContract.balanceOf(web3Data.account);
      setUserBalance((balance / 10 ** process.env.REACT_APP_AETERNA_DECIMAL).toFixed(4));
      let userInfo = await poolContract.userInfo(0, web3Data.account);
      const poolInfo = await poolContract.poolInfo(0);
      // const earned = userInfo.rewardDebt / 10 ** process.env.REACT_APP_AETERNA_DECIMAL;
      const locked_value =
        (poolInfo.lpSupply / 10 ** process.env.REACT_APP_AETERNA_DECIMAL) * tokenPrice;
      setTotalValueLocked(locked_value.toFixed(4));
      const _holding = userInfo.amount / 10 ** process.env.REACT_APP_AETERNA_DECIMAL;
      const _holdingUSD = _holding * tokenPrice;
      setEarned(_holding.toFixed(4));
      setUnStakeBalance(_holding.toFixed(4));
      setMyHolding(_holdingUSD.toFixed(4));
      const _pendingReward = await poolContract.pendingAeterna(0, web3Data.account);
      setPendingReward((_pendingReward / 10 ** process.env.REACT_APP_AETERNA_DECIMAL).toFixed(4));
      const _blockReward = await poolContract.aeternaPerBlock();
      const dailyReward = (_blockReward * 20 * 60 * 24);
      let apr = (dailyReward * 365) / poolInfo.lpSupply;
      if (poolInfo.lpSupply == 0) apr = 0;
      apr = ((apr) * 100).toFixed(2);
      setAPR(apr);
      setIsLoading(false);
    } catch (err) {
      console.log("initStaking function is failed. \n", err);
      setIsLoading(false);
    }
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  const setMaxBalance = async () => {
    const tokenContract = getContract(web3Data.chainId == BSC_Mainnet ? getContractsAddress(BSC_Mainnet).tokenAddress : getContractsAddress(BSC_Testnet).tokenAddress, tokenAbi);
    const balance = await tokenContract.balanceOf(web3Data.account);
    setStakeAmount(balance / 10 ** process.env.REACT_APP_AETERNA_DECIMAL);
  }

  const setMaxUnstake = async () => {
    const _poolAddress = getContractsAddress(web3Data.chainId).PoolAddress;
    const poolContract = getContract(_poolAddress, erc20FarmAbi);
    let userInfo = await poolContract.userInfo(0, web3Data.account);
    const _holding = userInfo.amount / 10 ** process.env.REACT_APP_AETERNA_DECIMAL;
    setUnStakeAmount(_holding);
  }

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer)
        initStaking();
    }
  }, [web3Data.connected]);

  return (
    <div className="container mx-auto h-full">
      <div className="nftstake top-0 xl:top-[8vh]">
        <div className="nftstake-container flex flex-col xl:flex-row justify-center mx-auto">
          <LinearCard className="harvest-card mx-3 my-10">
            {/* <div className="earnable-token">
              <p>AETERNA Earned</p>
              <div className="flex flex-row items-center">
                <p>845.12</p>
                <img src={tokenLogo} alt="Aeterna Token" />
              </div>
            </div> */}

            <div className="tvl-apr-token">
              <div className="flex flex-row items-center justify-between mb-5">
                <p>TVL</p>
                <StyledContentLoader backgroundColor={"#262626"} isLoading={isLoading}>
                  <p>$ {totalValueLocked}</p>
                </StyledContentLoader>
              </div>
              <div className="flex flex-row items-center justify-between">
                <p>Approximate APR</p>
                <StyledContentLoader backgroundColor={"#262626"} isLoading={isLoading}>
                  <p>{APR} %</p>
                </StyledContentLoader>
              </div>
            </div>

            <div className="earnable-token">
              <p>AETERNA Staked</p>
              <div className="flex flex-row items-center">
                <StyledContentLoader backgroundColor={"#262626"} isLoading={isLoading}>
                  <p>{earned}</p>
                </StyledContentLoader>
                <img src={tokenLogo} alt="Aeterna Token" />
              </div>
            </div>

            <div className="holding flex flex-col sm:flex-row justify-between">
              <div className="my-holding">
                <p className="mr-8">My Holding</p>
                <StyledContentLoader backgroundColor={"#262626"} isLoading={isLoading}>
                  <p>${myHolding}</p>
                </StyledContentLoader>
              </div>
              <div className="pending-reward ml-0 sm:ml-8">
                <p className="m-0 sm:mr-6">Pending Rewards</p>
                <div className="flex flex-row items-center">
                  <StyledContentLoader backgroundColor={"#262626"} isLoading={isLoading}>
                    <p>{pendingReward}</p>
                  </StyledContentLoader>
                  <img src={tokenLogo} alt="Aeterna Token" />
                </div>
              </div>
            </div>
            <button className="harvest-btn" onClick={() => harvest()}>
              {isButtonLoading && buttonIndex == 2 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="loader" style={{ color: "white" }} role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                "Harvest"
              )}
            </button>
          </LinearCard>

          <LinearCard className="stake-card mx-3 my-10">
            <div className="stake-button-group">
              <button
                className={`stake-select-btn ${selectStake ? "active" : ""}`}
                onClick={() => setSelectStake(true)}
              >
                Stake
              </button>
              <button
                className={`unstake-select-btn ${!selectStake ? "active" : ""}`}
                onClick={() => setSelectStake(false)}
              >
                Unstake
              </button>
            </div>
            {selectStake ? (
              <>
                <div className="stake-balance flex flex-row justify-between">
                  <p className="">Stake AETERNA</p>
                  <StyledContentLoader backgroundColor={"#262626"} isLoading={isLoading}>
                    <p className="">Balance: {userBalance}</p>
                  </StyledContentLoader>
                </div>
                <div className="flex flex-col justify-between flex-1">
                  <div className="stake-input">
                    <div className="inputWithButton">
                      <input
                        type="number"
                        default="0"
                        min={0}
                        value={stakeAmount}
                        onChange={handleStakeAmount}
                      />
                      <button onClick={() => setMaxBalance()}>MAX</button>
                    </div>
                    <div className="stake-token">
                      <img src={tokenLogo} alt="Token Logo"></img>
                      <p>AETERNA</p>
                    </div>
                  </div>
                  <button className="stake-btn" onClick={async () => { await stake(); await initStaking(); }}>
                    {isButtonLoading && buttonIndex == 0 ? (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="loader" style={{ color: "white" }} role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      isApproved ? "STAKE" : "ENABLE"
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="stake-balance flex flex-row justify-between">
                  <p className="">Unstake AETERNA</p>
                  <StyledContentLoader backgroundColor={"#262626"} isLoading={isLoading}>
                    <p className="">Balance: {unStakeBalance}</p>
                  </StyledContentLoader>
                </div>
                <div className="flex flex-col justify-between flex-1">
                  <div className="stake-input">
                    <div className="inputWithButton">
                      <input
                        type="number"
                        default="0"
                        min={0}
                        value={unStakeAmount}
                        onChange={(e) => setUnStakeAmount(e.target.value)}
                      />
                      <button onClick={() => setMaxUnstake()}>MAX</button>
                    </div>
                    <div className="stake-token">
                      <img src={tokenLogo} alt="Token Logo"></img>
                      <p>AETERNA</p>
                    </div>
                  </div>
                  <button className="stake-btn" onClick={async () => { await unStake(); await initStaking(); }}>
                    {isButtonLoading && buttonIndex == 1 ? (
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="loader" style={{ color: "white" }} role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      "UNSTAKE"
                    )}
                  </button>
                </div>
              </>
            )}
          </LinearCard>
        </div>
      </div>
    </div>
  );
}
