import { useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiQuestionMark } from "react-icons/bi";
import { getFormatEther } from "../../../web3/getEthers";
import TokenLogo from "../../TokenLogo";

export default function PairLiquidity({ liquidity, slippage, selected }) {
  const { lpBalance, lpDecimals, token0, token1, reserve, poolShare } = liquidity;
  const [expandToken, setExpandToken] = useState(false);

  console.log(liquidity);
  return (
    <div className="pair-liquidity-tokens my-1">
      <div className="pair-liquidity-token">
        <div className="pair-token-logo">
          <TokenLogo width="40px" height="40px">
            {token0.logoURI === undefined ? (
              <BiQuestionMark style={{ fontSize: "30px" }} />
            ) : (
              <img src={token0.logoURI} alt="First Token Logo" />
            )}
          </TokenLogo>
          <TokenLogo width="40px" height="40px">
            {token1.logoURI === undefined ? (
              <BiQuestionMark style={{ fontSize: "30px" }} />
            ) : (
              <img src={token1.logoURI} alt="Sec Token Logo" />
            )}
          </TokenLogo>
          <h6>
            {token0.symbol}/{token1.symbol}
          </h6>
        </div>
        <button className="manage-tokens" onClick={() => setExpandToken(!expandToken)}>
          Manage
          <MdKeyboardArrowDown style={{ color: "#fff" }} />
        </button>
      </div>
      {expandToken && (
        <div>
          <div className="pool-token-info">
            <div className="flex flex-row justify-between items-center mb-1 text-left">
              <p>Your total pool tokens:</p>
              <p>{parseFloat(lpBalance / (10 ** lpDecimals)).toFixed(4)}</p>
            </div>
            <div className="flex flex-row justify-between items-center mb-1 text-left">
              <p>Pool tokens in rewards pool:</p>
              <p className="text-sky-400">0</p>
            </div>
            <div className="flex flex-row justify-between items-center mb-1 text-left">
              <p>Pooled {token0.symbol}:</p>
              <div className="flex flex-row items-center">
                <p className="mr-2">{parseFloat(reserve.reserve0 / (10 ** token0.decimals)).toFixed(4)}</p>
                <TokenLogo width="30px" height="30px">
                  {token0.logoURI === undefined ? (
                    <BiQuestionMark style={{ fontSize: "20px" }} />
                  ) : (
                    <img src={token0.logoURI} alt="First Token Logo" />
                  )}
                </TokenLogo>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center mb-1 text-left">
              <p>Pooled {token1.symbol}:</p>
              <div className="flex flex-row items-center">
                <p className="mr-2">{parseFloat(reserve.reserve1 / (10 ** token1.decimals)).toFixed(4)}</p>
                <TokenLogo width="30px" height="30px">
                  {token1.logoURI === undefined ? (
                    <BiQuestionMark style={{ fontSize: "20px" }} />
                  ) : (
                    <img src={token1.logoURI} alt="Sec Token Logo" />
                  )}
                </TokenLogo>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center mb-1 text-left">
              <p>Your pool share:</p>
              <p>{poolShare}%</p>
            </div>
          </div>
          <div className="pool-token-control">
            <Link
              to="/exchange/liquidity/add"
              state={{
                liquidity: liquidity,
                slippage: slippage,
                selected: selected,
              }}
              className="add-pair-token"
            >
              Add
            </Link>
            <Link
              to="/exchange/liquidity/remove"
              state={{
                liquidity: liquidity,
                slippage: slippage,
                selected: selected,
              }}
            >
              Remove
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
