import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Home from "./Home";
import Dashboard from "./Dashboard";
import Exchange from "./Exchange";
import Swap from "./Swap";
import Liquidity from "./Liquidity";
import LiquidityList from "../components/Liquidity/LiquidityList";
import AddLiquidity from "../components/Liquidity/AddLiquidity";
import RemoveLiquidity from "../components/Liquidity/RemoveLiquidity";
import LotteryWrap from "./LotteryWrap";
import Lottery from "./Lottery";
import PastDraw from "./PastDraw";
import NFTStaking from "./NFTStaking";
import Presale from "./Presale";
import UserInfo from "./UserInfo";
import PastTicket from "./PastTicket";
import CurrentTicket from "./CurrentTicket";
import PickTicket from "./PickTicket";
import ContactUs from "./ContactUs";
import EscrowDashboard from "./EscrowDashboard";
import EscrowProduct from "./EscrowProduct";
import EscrowService from "./EscrowService";
import EscrowCryptoTrading from "./EscrowCryptoTrading";
import EscrowPostBulletin from "./EscrowPostBulletin";
import EscrowChat from "./EscrowChat";
import { Toaster } from "react-hot-toast";

export default function Pages() {
  const location = useLocation();
  const [showMobile, setShowMobile] = useState(false);

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "white",
              paddingLeft: 40,
              paddingRight: 40,
              fontWeight: 500,
            },
          },
          error: {
            style: {
              background: "#121212",
              width: "100%",
              color: "#e74c3c",
              paddingLeft: 40,
              paddingRight: 40,
              fontWeight: 500,
            },
          },
        }}
      />
      <Sidebar showMobile={showMobile} handleMobile={(show) => setShowMobile(show)} />

      <div className="app-container">
        <Header showMobile={showMobile} handleMobile={(show) => setShowMobile(show)} />

        <div className="app-content">
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/exchange/*" element={<Exchange />}>
              <Route path="swap" element={<Swap />} />
              <Route path="liquidity/*" element={<Liquidity />}>
                <Route index element={<LiquidityList />} />
                <Route path="list" element={<LiquidityList />} />
                <Route path="add" element={<AddLiquidity />} />
                <Route path="remove" element={<RemoveLiquidity />} />
              </Route>
            </Route>
            <Route path="/staking" element={<NFTStaking />} />
            <Route path="/nftstaking" element={<NFTStaking />} />
            <Route path="/lottery/*" element={<LotteryWrap />}>
              <Route index element={<Lottery />} />
              <Route exact path="list" element={<PastDraw />} />
              <Route exact path="past/ticket" element={<PastTicket />} />
              <Route exact path=":lotteryId/current/ticket" element={<CurrentTicket />} />
              <Route exact path="pick/ticket" element={<PickTicket />} />
            </Route>
            <Route path="/presale" element={<Presale />} />
            <Route path="/account" element={<UserInfo />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/escrow" element={<Navigate replace to="/escrow/dashboard" />} />
            <Route path="/escrow/dashboard" element={<EscrowDashboard />} />
            <Route path="/escrow/product" element={<EscrowProduct />}>
              <Route path=":tradeId" element={<EscrowProduct />} />
            </Route>
            <Route path="/escrow/service" element={<EscrowService />}>
              <Route path=":tradeId" element={<EscrowService />} />
            </Route>
            <Route path="/escrow/cryptotrading" element={<EscrowCryptoTrading />}>
              <Route path=":tradeId" element={<EscrowCryptoTrading />} />
            </Route>
            <Route path="/escrow/postbulletin" element={<EscrowPostBulletin />}>
              <Route path=":bulletinId" element={<EscrowPostBulletin />} />
            </Route>
            <Route path="/escrow/chat" element={<EscrowChat />}>
              <Route path=":chatId" element={<EscrowChat />} />
            </Route>
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </div>

        {
          location.pathname === '/home' ? "" :
            <div className="bg-[#15152e] border-t-4 border-solid border-white py-4">
              <div className="container mx-auto px-4 flex justify-end">
                <Link to="/contactus" className="flex items-center w-fit text-white">
                  <div><HiOutlineMail className="w-5 text-xl mx-2" /></div>
                  <p className=" hover:text-[#4f4ca8] hover:underline text-md text-center sm:text-right font-normal leading-[30px]">Contact us</p>
                </Link>
              </div>
            </div>
        }
      </div>
    </>
  );
}
