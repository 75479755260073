import CircleNumber from "./CircleNumber";

export default function TicketCard(props) {

  const winNumber = [32, 15, 11, 41, 12, 26];

  return (
    <div className="ticket-card">
      <img src={props.logo} alt="Ticket image" />
      <div className="card-wrap">
        {
          Array(6 - props.match).fill().map((item, index) => <CircleNumber key={index} number={index + 1} />)
        }
        {
          Array(props.match).fill().map((item, index) => <CircleNumber key={index} number={winNumber[index]} green={true} />)
        }
      </div>
    </div>
  );
}
