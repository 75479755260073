import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Ticket from "../components/Lottery/Ticket";
import { MdArrowBack } from "react-icons/md";
import { getLotteryContract } from "../web3/getLotteryContract";
import { web3ModalContext } from "../components/Web3ModalProvider";

export default function CurrentTicket() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet } = useContext(web3ModalContext);
  const [tickets, setTickets] = useState(null);
  const navigate = useNavigate();
  const { lotteryId } = useParams();
  const lotteryContract = getLotteryContract();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    rows: 4,
    centerPadding: "60px",
    slidesPerRow: 1,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "30px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "30px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerPadding: "30px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, connected: true, provider, library, signer, account, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  const getTickets = async (lotteryId) => {
    const userInfo = await lotteryContract.viewUserInfoForLotteryId(web3Data.account, lotteryId, 0, 1000);
    setTickets([...userInfo[1]]);
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getTickets(lotteryId);
    }
  }, [web3Data.connected]);

  return (
    <div className="container mx-auto">
      <div className="current-ticket">
        <button
          // to="/lottery/list"
          onClick={() => navigate(-1)}
          className="absolute flex items-center button text-white rounded-lg border border-white px-1 w-fit"
        >
          <MdArrowBack style={{ color: "#fff", marginRight: "4px" }} /> Back
        </button>

        <h3 className="text-center">Your Ticket</h3>

        {tickets ? (
          tickets.length > 12 ? (
            <div className="current-ticket-carousel">
              <Slider {...settings}>
                {tickets.map((ticket, i) => (
                  <Ticket key={i} pickedNumber={ticket.numbers} />
                ))}
              </Slider>
            </div>
          ) : 0 < tickets.length && tickets.length <= 12 ? (
            <div className="grid lg:grid-cols-3">
              {tickets.map((ticket, i) => (
                <Ticket key={i} pickedNumber={ticket.numbers} />
              ))}
            </div>
          ) : (
            <h3 className="text-center text-5xl my-8">No Tickets</h3>
          )
        ) : (
          <h3 className="text-center text-5xl my-8">No Tickets</h3>
        )}
      </div>
    </div>
  );
}
