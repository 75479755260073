import { useContext, useEffect, useState } from "react";
import { MdHistory, MdSettings, MdClose } from "react-icons/md";
import ModalWrap from "../Modal";
import { SwapSettingContext } from "../SwapSettingProvider";

export default function SwapHeader() {
  const { slippage, setSlippage, timeline, setTimeline } = useContext(SwapSettingContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState();
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    if (slippage > 49) setSlippage(49);
    else if (slippage <= 0) setSlippage(0.1);
    if (timeline < 1) setTimeline(2);
    setIsOpen(false);
  }

  const selectSlippage = (percent, selected) => {
    setSlippage(percent);
    setSelected(selected);
  };

  const settingTimeline = (timeline) => {
    setTimeline(timeline);
  }

  useEffect(() => {
    switch (slippage) {
      case "0.1":
        selectSlippage(slippage, 0);
        break;
      case "0.5":
        selectSlippage(slippage, 1);
        break;
      case "1":
        selectSlippage(slippage, 2);
        break;
      case "5":
        selectSlippage(slippage, 3);
        break;
      default:
        setSelected(slippage, -1);
        break;
    }
  }, []);

  return (
    <>
      <div className="swap-header">
        <div className="flex flex-row justify-between mb-1">
          <h4>Swap</h4>
          <div className="flex flex-row">
            <button className="swap-history mr-3">
              <MdHistory style={{ width: "24px", height: "24px", color: "#fff" }} />
            </button>
            <button className="swap-setting" onClick={openModal}>
              <MdSettings style={{ width: "24px", height: "24px", color: "#fff" }} />
            </button>
          </div>
        </div>
        <p>Fast, Secure, KYC -Free</p>
      </div>
      <ModalWrap isOpen={isOpen} cbClose={closeModal}>
        <header className="modal-header">
          <div className="flex flex-row justify-between items-center">
            <h6>Setting</h6>
            <button onClick={closeModal}>
              <MdClose style={{ width: "16px", height: "16px", color: "#fff" }} />
            </button>
          </div>
        </header>
        <div className="modal-body">
          <div className="setting">
            <div className="slippage mb-4">
              <h4>Slippage Tolerlance</h4>
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <button className={`percent ${selected == 0 ? "active" : ""}`} onClick={() => selectSlippage(0.1, 0)}>
                  0.1%
                </button>
                <button className={`percent ${selected == 1 ? "active" : ""}`} onClick={() => selectSlippage(0.5, 1)}>
                  0.5%
                </button>
                <button className={`percent ${selected == 2 ? "active" : ""}`} onClick={() => selectSlippage(1, 2)}>
                  1.0%
                </button>
                <button className={`percent ${selected == 3 ? "active" : ""}`} onClick={() => selectSlippage(5, 3)}>
                  5.0%
                </button>
                <input
                  type="number"
                  className="percent custom"
                  placeholder="Input slippage"
                  value={slippage}
                  max={49}
                  onChange={e => selectSlippage(e.target.value, -1)}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <h4 className="text-xl font-medium">Tx deadline (mins)</h4>
              <div className="">
                <input
                  type="number"
                  className="w-20 bg-[#121425] rounded-2xl font-medium"
                  placeholder="20"
                  value={timeline}
                  onChange={e => settingTimeline(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalWrap>
    </>
  );
}
