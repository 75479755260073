import { useContext, useState } from "react";
import { BsDice5 } from "react-icons/bs";
import { TbEraser } from "react-icons/tb";
import { MdHelpOutline } from "react-icons/md";
import { PickNumberContext } from "../../pages/PickTicket";
import NumberPanel from "./NumberPanel";
import { getRandomInt } from "../../utils";
import { v4 as uuid } from "uuid";
import ModalWrap from "../Modal";

export default function ChooseNumber() {
  const { pickedNum, setPickedNum, ticketCount } = useContext(PickNumberContext);

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const clearAll = () => {
    setPickedNum([]);
  };

  const autofillAll = () => {
    let list = pickedNum;

    if (ticketCount <= pickedNum.length) return;
    for (let i = 0; i < ticketCount - pickedNum.length; i++) {
      const id = uuid().slice(0, 8);
      let random = [];
      let count = 0;
      while (count < 6) {
        const randomInt = getRandomInt(1, 65);
        const index = random.indexOf(randomInt);
        if (index > -1) continue;
        else random.push(randomInt);
        count++;
      }
      const addTicket = { id: id, pick: random };
      list = [...list, addTicket];
      // list.push(addTicket);
      console.log(list);
      setPickedNum([...list]);
    }
  };

  return (
    <div className="choose-number">
      <div className="headline">
        <button className="flex justify-start items-center px-1 rounded border" onClick={clearAll}>
          <TbEraser style={{ fontSize: "17px", color: "#707070", marginRight: "4px" }} />
          <p>CLEAR ALL</p>
        </button>
        <button className="flex justify-start items-center px-1 mx-3 rounded border" onClick={autofillAll}>
          <BsDice5 style={{ fontSize: "17px", color: "#707070", marginRight: "4px" }} />
          <p>AUTO-FILL ALL</p>
        </button>
        <button className="flex justify-start items-center px-1 rounded border" onClick={openModal}>
          <MdHelpOutline style={{ fontSize: "17px", color: "#707070", marginRight: "4px" }} />
          <p>HOW TO PLAY?</p>
        </button>
      </div>
      <NumberPanel />
      <ModalWrap isOpen={isOpen} cbClose={closeModal}>
        <header className="modal-header">
          <div className="flex flex-row justify-between items-center">
            <h6>How to Play Aeterna Lottery:</h6>
          </div>
        </header>
        <div className="modal-body">
          <div className="setting">
            <h4>Step 1: Buy Your Ticket</h4>
            <p>To begin, you must first purchase a ticket.</p>
            <p>If you are an Aeterna holder, you can buy a ticket for $1 a piece.</p>
            <p>If you're not an Aeterna holder, you'll need to buy a ticket for $2 a piece.</p>
            <p>You can buy as many tickets as you like.</p>
            <br />

            <h4>Step 2: Choose Your Numbers</h4>
            <p>Each ticket contains 65 potential numbers to choose from. You must choose 6 numbers between 1-65 to participate. You can choose your own numbers or use the computer-generated 'Quick Pick' option to randomly select your numbers. Make sure your numbers are correct and that they match your chosen price tier (Aeterna holder or non-Aeterna holder). </p>
            <br />
            <h4>Step 3: Wait for the Draw</h4>
            <p>Each lottery draw happens 3 times a day, every 8 hours. Keep hold of your ticket(s) and wait for the next draw time. If no one wins the lottery in the previous round, the prize pool carries over to the next round.</p>
            <br />
            <h4>Step 4: Check Your Numbers</h4>
            <p>Once the draw happens, check your numbers against the winning numbers. If your numbers match all 6 numbers drawn, you win the grand prize. If you match 5 numbers, you win a smaller prize. If you match 4 numbers, you win an even smaller prize. If you match 3 or fewer numbers, you don't win anything.</p>
            <br />
            <h4>Step 5: Claim Your Prize</h4>
            <p>
              If you win, congratulations! You'll need to claim your prize before the next round of draws. For more information on how to claim prizes, visit the lottery website or contact lottery officials.</p>
            <br />
            <h4>
              And that's it! That's how you play the Aeterna lottery. Good luck!
            </h4>
            <br />
          </div>
        </div>
      </ModalWrap >
    </div >
  );
}
