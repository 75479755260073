import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import getContractsAddress from "../../web3/contractsAddress";
import { BiQuestionMark } from "react-icons/bi";
import { MdSettings, MdArrowDownward, MdClose } from "react-icons/md";
import LiquidityHeader from "./component/LiquidityHeader";
import LiquidityBody from "./component/LiquidityBody";
import ModalWrap from "../Modal";
import PancakeswapRouter from "../../web3/abi/PancakeswapRouter.json";
import Lp_abi from "../../web3/abi/Lp_abi.json";
import tokenAbi from "../../web3/abi/tokenAbi.json";
import { mainnetTokens } from "../../constant/tokens";
import { web3ModalContext } from "../Web3ModalProvider";
import { notify } from "../../utils";
import { SwapSettingContext } from "../SwapSettingProvider";
import timestring from "timestring";

export default function RemoveLiquidity() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract, getFormatEther,
    getBignumberFrom, getBlockTimestamp } = useContext(web3ModalContext);
  const { slippage, setSlippage, timeline, setTimeline } = useContext(SwapSettingContext);
  const location = useLocation();
  const routerAddress = getContractsAddress(web3Data.chainId).PancakeswapRouter;
  const factoryAddress = getContractsAddress(web3Data.chainId).PancakeswapFactory;
  const { liquidity } = location.state || {};

  // console.log(beforeSlippage);
  const [selected, setSelected] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [token0, setToken0] = useState({});
  const [token1, setToken1] = useState({});
  const [removeToken0, setRemoveToken0] = useState(0);
  const [removeToken1, setRemoveToken1] = useState(0);
  const [reserve0, setReserve0] = useState(0);
  const [reserve1, setReserve1] = useState(0);
  const [token0Share, setToken0Share] = useState(0);
  const [token1Share, setToken1Share] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [removeValue, setRemoveValue] = useState(0);
  const [approved, setApproved] = useState(false);
  const [message, setMessage] = useState({});
  const [signature, setSignature] = useState({});
  const [lpBalance, setLpBalance] = useState(0);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    if (slippage > 49) setSlippage(49);
    else if (slippage <= 0) setSlippage(0.1);
    if (timeline < 1) setTimeline(2);
    setIsOpen(false);
  }

  const selectSlippage = (percent, selected) => {
    setSlippage(percent);
    setSelected(selected);
  };

  const EIP712Domain = [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "chainId", type: "uint256" },
    { name: "verifyingContract", type: "address" },
  ];
  const Permit = [
    { name: "owner", type: "address" },
    { name: "spender", type: "address" },
    { name: "value", type: "uint256" },
    { name: "nonce", type: "uint256" },
    { name: "deadline", type: "uint256" },
  ];

  const handleApprove = async () => {
    try {
      setApproveLoading(true);

      const domain = {
        name: "Pancake LPs",
        version: "1",
        chainId: web3Data.chainId,
        verifyingContract: liquidity.pairAddress,
      };

      const pairContract = getContract(liquidity.pairAddress, Lp_abi);
      const deadline = (await getBlockTimestamp()) + timestring(timeline + 'm');
      const nonce = await pairContract.nonces(web3Data.account);
      const value = await pairContract.balanceOf(web3Data.account);
      setLpBalance(value);

      const message = {
        owner: web3Data.account,
        spender: routerAddress,
        value: value.mul(removeValue).div(100).toString(),
        nonce: nonce.toHexString(),
        deadline: deadline,
      };

      const data = JSON.stringify({
        types: {
          EIP712Domain,
          Permit,
        },
        domain,
        primaryType: "Permit",
        message,
      });

      const signature = await web3Data.signer.provider.send("eth_signTypedData_v4", [web3Data.account, data]);
      const sigBreakdown = ethers.utils.splitSignature(signature);
      setSignature({
        v: sigBreakdown.v,
        r: sigBreakdown.r,
        s: sigBreakdown.s,
        deadline: deadline,
      });
      setMessage(message);

      setApproved(true);
      notify({ text: `Approve ${token0.symbol} ${token1.symbol}` });
      setApproveLoading(false);
    } catch (err) {
      console.log(err);
      setApproveLoading(false);
    }
  };

  const confirm = async () => {
    try {
      setConfirmLoading(true);
      const routerContract = getContract(routerAddress, PancakeswapRouter);
      const remove = await routerContract.removeLiquidityWithPermit(
        token0.address,
        token1.address,
        getBignumberFrom(lpBalance).mul(removeValue).div(100),
        getBignumberFrom(reserve0)
          .mul(removeValue * (100 - slippage))
          .div(10000),
        getBignumberFrom(reserve1)
          .mul(removeValue * (100 - slippage))
          .div(10000),
        web3Data.account,
        signature.deadline,
        false,
        signature.v,
        signature.r,
        signature.s
      );

      await remove.wait();
      setRemoveValue(0);
      setConfirmLoading(false);
      setApproved(false);
      notify({
        text: `Remove ${token0.symbol} and ${token1.symbol}`,
        link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${remove.hash}`,
      });
    } catch (err) {
      setConfirmLoading(false);
      console.log("confirm is failed.\n", err);
    }
  }

  const handleConfirm = async () => {
    try {
      await confirm();
      if (liquidity) {
        console.log(liquidity)
        // const newLiquidity = await getPair(liquidity.pairAddress);
        setTimeout(async () => {
          const newLiquidity = await getPair(liquidity.pairAddress);
          console.log(newLiquidity);
          handleLiquidity(newLiquidity);
        }, 4000);
      }
    } catch (err) {
      console.log("handleConfirm is failed.\n", err);
      setConfirmLoading(false);
    }
  };

  const handleRemoveValue = (rate) => {
    setRemoveValue(rate);
    // console.log(reserve0._hex, (reserve0._hex * (rate / 100) / (10 ** token0.decimals)).toFixed(4))
    setRemoveToken0((reserve0._hex * (rate / 100) / (10 ** token0.decimals)).toFixed(4));
    setRemoveToken1((reserve1._hex * (rate / 100) / (10 ** token1.decimals)).toFixed(4));
    setApproved(false);
  };

  const handleLiquidity = (liquidity) => {
    if (liquidity) {
      setToken0(liquidity.token0);
      setToken1(liquidity.token1);
      setReserve0(liquidity.reserve.reserve0);
      setReserve1(liquidity.reserve.reserve1);
      const token0Share = getFormatEther(liquidity.reserve.reserve0) / getFormatEther(liquidity.reserve.reserve1);
      const token1Share = getFormatEther(liquidity.reserve.reserve1) / getFormatEther(liquidity.reserve.reserve0);
      setToken0Share(token0Share);
      setToken1Share(token1Share);
    }
  };

  const getTokenInfo = async (address) => {
    try {
      const tokenContract = getContract(address, tokenAbi);
      const tokenBalance = await tokenContract.balanceOf(web3Data.account);
      const tokenTotalSupply = await tokenContract.totalSupply();
      const tokenSymbol = await tokenContract.symbol();
      const tokenName = await tokenContract.name();
      const tokenDecimals = await tokenContract.decimals();
      const tokenLogoURI = mainnetTokens.filter((token) => token.symbol === tokenSymbol)[0].logoURI;

      return {
        address: address,
        // contract: tokenContract,
        name: tokenName,
        symbol: tokenSymbol,
        balance: tokenBalance,
        decimals: tokenDecimals,
        totalSupply: tokenTotalSupply,
        logoURI: tokenLogoURI,
      };
    } catch (err) {
      console.log("useEffect getTokenInfo is failed for " + address + ".\n", err);
    }
  };

  const getPair = async (address) => {
    try {
      const pairContract = getContract(address, Lp_abi);
      console.log(pairContract, await pairContract.token0(), await pairContract.token1())
      const lpBalance = await pairContract.balanceOf(web3Data.account);
      const lpTotalSupply = await pairContract.totalSupply();
      const poolShare = parseFloat((lpBalance / lpTotalSupply) * 100).toFixed(2);
      const token0 = await getTokenInfo(await pairContract.token0());
      const token1 = await getTokenInfo(await pairContract.token1());
      const reserve = await pairContract.getReserves();

      console.log(token0, token1)
      return {
        pairAddress: address,
        lpBalance,
        lpTotalSupply,
        reserve,
        token0,
        token1,
        poolShare,
      };
    } catch (err) {
      console.log("useEffect getPair is failed.\n", err);
    }
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) {
        handleLiquidity(liquidity);
      }
    }
  }, [web3Data.connected, liquidity]);

  return (
    <>
      <LiquidityHeader className="remove-liquidity-header">
        <div className="flex flex-row justify-between mb-1">
          <p>Remove Liquidity</p>
          <button className="liquidity-setting" onClick={openModal}>
            <MdSettings style={{ width: "24px", height: "24px", color: "#fff" }} />
          </button>
        </div>
      </LiquidityHeader>
      <LiquidityBody>
        <div className="remove-liquidity">
          <div className="remove-liquidity-control">
            <div className="remove-liquidity-percent">
              <p>Amount to remove</p>
              <div className="inputWithButton">
                <button onClick={() => handleRemoveValue(100)}>MAX</button>
                <input
                  type="number"
                  placeholder="0"
                  min={0}
                  max={100}
                  value={removeValue > 0 ? (removeValue > 100 ? 100 : parseInt(removeValue)) : ""}
                  onChange={(e) => handleRemoveValue(e.target.value)}
                />
                <p className="text-lg font-medium"> %</p>
              </div>
            </div>
            <button
              className="p-2 rounded-full"
              style={{
                background: "#181c33",
                border: "1px solid #707070",
                zIndex: 1,
                margin: "-8px 0",
              }}
            >
              <MdArrowDownward style={{ width: "32px", height: "32px", color: "#fff" }} />
            </button>
            <div className="receive-token">
              <p>You Will Receive</p>
              <div className="receive-token-pair">
                <div className="recieve-token-amount flex flex-row items-center mr-4">
                  {token0.logoURI == undefined ? (
                    <div className="token-logo">
                      <BiQuestionMark style={{ fontSize: "30px" }} />
                    </div>
                  ) : (
                    <img src={token0.logoURI} alt="First token" />
                  )}
                  <div>
                    <p>{removeValue > 0 ? parseFloat(removeToken0).toFixed(6) : "-"}</p>
                    <p>{token0.symbol}</p>
                  </div>
                </div>
                <div className="recieve-token-amount flex flex-row items-center">
                  {token1.logoURI == undefined ? (
                    <div className="token-logo">
                      <BiQuestionMark style={{ fontSize: "30px" }} />
                    </div>
                  ) : (
                    <img src={token1.logoURI} alt="Second token" />
                  )}
                  <div>
                    <p>{removeValue > 0 ? parseFloat(removeToken1).toFixed(6) : "-"}</p>
                    <p>{token1.symbol}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="remove-liquidity-button-group">
            <button className="approve-remove-liquidity" onClick={handleApprove} disabled={approved}>
              Approve
            </button>
            <button
              className={removeValue <= 0 ? "enter-remove-liquidity" : "confirm-remove-liquidity"}
              disabled={!approved}
              onClick={handleConfirm}
            >
              {confirmLoading && (
                <Oval
                  height={16}
                  width={16}
                  color="#ffffff"
                  wrapperStyle={{ marginRight: "8px" }}
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="transparent"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              )}
              {removeValue <= 0 ? "Enter an amount" : "Confirm Withdrawal"}
            </button>
          </div>
        </div>
      </LiquidityBody>
      <ModalWrap isOpen={isOpen} cbClose={closeModal}>
        <header className="modal-header">
          <div className="flex flex-row justify-between items-center">
            <h6>Setting</h6>
            <button onClick={closeModal}>
              <MdClose style={{ width: "16px", height: "16px", color: "#fff" }} />
            </button>
          </div>
        </header>
        <div className="modal-body">
          <div className="setting">
            <div className="slippage">
              <h4>Slippage Tolerlance</h4>
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <button className={`percent ${selected == 0 ? "active" : ""}`} onClick={() => selectSlippage(0.1, 0)}>
                  0.1%
                </button>
                <button className={`percent ${selected == 1 ? "active" : ""}`} onClick={() => selectSlippage(0.5, 1)}>
                  0.5%
                </button>
                <button className={`percent ${selected == 2 ? "active" : ""}`} onClick={() => selectSlippage(1, 2)}>
                  1.0%
                </button>
                <button className={`percent ${selected == 3 ? "active" : ""}`} onClick={() => selectSlippage(5, 3)}>
                  5.0%
                </button>
                <input
                  type="number"
                  className="percent custom"
                  placeholder="Input slippage"
                  value={slippage}
                  max={49}
                  onChange={e => selectSlippage(e.target.value, -1)}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <h4 className="text-xl font-medium">Tx deadline (mins)</h4>
              <div className="">
                <input
                  type="number"
                  className="w-20 bg-[#121425] rounded-2xl font-medium"
                  placeholder="20"
                  value={timeline}
                  onChange={e => setTimeline(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalWrap>
    </>
  );
}
