import React, { useState } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Modal from "react-modal";
import Pages from "./pages";
import Presale from "./pages/Presale";
import Web3ModalProvider from "./components/Web3ModalProvider";
import SwapSettingProvider from "./components/SwapSettingProvider";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./assets/sass/style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/style.css";

Modal.setAppElement("#root");

function getLibrary(provider) {
  return new Web3(provider);
}

export default function App() {
  const [showMobile, setShowMobile] = useState(false);

  return (
    <Web3ModalProvider>
      <SwapSettingProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <div className="App">
            <Router>
              <Routes>
                <Route exact path="/presale" element={<Presale />} />
                <Route path="*" element={<Pages />} />
              </Routes>
            </Router>

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
              theme="dark"
            />
          </div>
        </Web3ReactProvider>
      </SwapSettingProvider>
    </Web3ModalProvider>
  );
}
