import { useState, useEffect, useContext } from "react";
import toast from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { BiQuestionMark } from "react-icons/bi";
// import { getFormatEther } from "../../../web3/getEthers";
// import getContractsAddress from "../../web3/contractsAddress";
import tokenAbi from "../../../web3/abi/tokenAbi.json";
import ModalWrap from "../../Modal";
import TokenCard from "./TokenCard";
import { mainnetTokens } from "../../../constant/tokens";
import tokenList from "../../../web3/pancakeswap-top-15";
import { BSC_Mainnet } from "../../../config";
import { web3ModalContext } from "../../Web3ModalProvider";
import TokenLogo from "../../TokenLogo";

// import AETERNAToken from '../../constant/tokens';

export default function LiquidityInput({
  tokenInfo,
  loading,
  successConfirm,
  overflow,
  handleValue,
  value,
  handleToken,
  approveToken,
  setApprove
}) {
  const { web3Data, getFormatEther, getFormatUnits } = useContext(web3ModalContext);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  // console.log(tokenInfo);
  const handleMax = () => {
    handleValue((tokenInfo.balance?._hex / (10 ** tokenInfo.decimals)).toString());
  };

  const handleInput = (value) => {
    if (value * (10 ** tokenInfo?.decimals) > (tokenInfo?.allowance)) {
      const token = approveToken.length > 0 ? approveToken?.filter((token) => token.symbol !== tokenInfo?.symbol) : [];
      console.log(token, approveToken);
      setApprove([...token, tokenInfo]);
    } else setApprove([]);
    handleValue(value);
  }

  useEffect(() => {
    if (web3Data.connected) {
      if (tokenInfo) {
        // console.log("tokenInfo", tokenInfo);
        if (parseFloat(value) > parseFloat(tokenInfo?.balance?._hex / (10 ** tokenInfo?.decimals))) overflow(true);
        else overflow(false);
        if (tokenInfo?.symbol === mainnetTokens[0].symbol) return;
        if (value * (10 ** tokenInfo?.decimals) > (tokenInfo?.allowance)) {
          const token = approveToken.length > 0 ? approveToken?.filter((token) => token.symbol !== tokenInfo?.symbol) : [];
          setApprove([...token, tokenInfo]);
        } else setApprove([]);
      }
    }
  }, [web3Data.connected, value]);

  return (
    <div className="liquidity-input">
      <div className="add-liqidity-token">
        <TokenLogo width="51px" height="51px">
          {!tokenInfo ? (
            <></>
          ) : tokenInfo.logoURI === undefined ? (
            <BiQuestionMark style={{ fontSize: "30px" }} />
          ) : (
            <img src={tokenInfo.logoURI} alt="Liquidity Token Logo" />
          )}
        </TokenLogo>
        <div className="token-name">
          <p>Input</p>
          {!tokenInfo ? (
            loading === true ? (
              <div className="flex flex-row justify-center">
                <Oval
                  height={16}
                  width={16}
                  color="#ffffff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="transparent"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              </div>
            ) : (
              <button className="add-token" onClick={openModal} disabled={web3Data.connected === false ? true : false}>
                Select a token
              </button>
            )
          ) : loading === true ? (
            <>
              <div className="flex flex-row justify-center">
                <Oval
                  height={16}
                  width={16}
                  color="#ffffff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="transparent"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              </div>
              <button className="flex flex-row items-center" onClick={openModal}>
                <h6>{!tokenInfo ? "" : tokenInfo.symbol}</h6>
                <MdKeyboardArrowDown style={{ color: "#fff" }} />
              </button>
            </>
          ) : (
            <button className="flex flex-row items-center" onClick={openModal}>
              <h6>{!tokenInfo ? "" : tokenInfo.symbol}</h6>
              <MdKeyboardArrowDown style={{ color: "#fff" }} />
            </button>
          )}
        </div>
      </div>

      <div className="add-liqidity-input">
        <div className="inputWithButton" style={{ background: (!tokenInfo || loading) && "#4c4a4a" }}>
          {!tokenInfo ? "" : <button onClick={handleMax}>MAX</button>}
          <input
            type="number"
            placeholder="0.00"
            min={0}
            value={value || ""}
            disabled={!tokenInfo || loading === true ? true : false}
            onChange={(e) => handleInput(e.target.value)}
          />
          <p>
            Balance: {!tokenInfo ? parseFloat(0).toFixed(2) : parseFloat(tokenInfo?.balance?._hex / (10 ** tokenInfo?.decimals)).toFixed(2)}
          </p>
        </div>
      </div>

      <ModalWrap isOpen={isOpen} cbClose={closeModal}>
        <header className="modal-header">
          <div className="flex flex-row justify-between items-center">
            <h6>Select a Token</h6>
            <button onClick={closeModal}>
              <MdClose style={{ width: "16px", height: "16px", color: "#fff" }} />
            </button>
          </div>
        </header>
        <div className="modal-body" style={{ height: "70vh" }}>
          <div className="token-list">
            {web3Data.chainId === BSC_Mainnet
              ? mainnetTokens.map((token, index) => (
                <TokenCard
                  key={index}
                  tokenInfo={token}
                  handleToken={(selectToken) => {
                    handleToken(selectToken);
                    closeModal();
                  }}
                />
              ))
              : mainnetTokens.map((token, index) => (
                <TokenCard
                  key={index}
                  tokenInfo={token}
                  handleToken={(token) => {
                    console.log("handleToken");
                    handleToken(token);
                    closeModal();
                  }}
                />
              ))}
          </div>
          <button className="manage-token">Manage Token</button>
        </div>
      </ModalWrap>
    </div>
  );
}
