import { useEffect, useState, useContext } from "react";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import { getFormatEther } from "../../web3/getEthers";
import { MdClose, MdKeyboardArrowDown } from "react-icons/md";
import { BiQuestionMark } from "react-icons/bi";
import ModalWrap from "../Modal";
import TokenLogo from "../TokenLogo";
import TokenCard from "../Liquidity/component/TokenCard";
import tokenList from "../../web3/pancakeswap-top-15";
import { mainnetTokens } from "../../constant/tokens";
import { web3ModalContext } from "../Web3ModalProvider";
import { SWAP_POSITION, BSC_Mainnet } from "../../config";

export default function SwapInput({ tokenInfo, direction, overflow, loading, value, handleToken, handleAmount, approveToken, setApprove }) {
  const { web3Data, getFormatEther, getFormatUnits } = useContext(web3ModalContext);

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (web3Data.connected) {
      if (tokenInfo) {
        if (parseFloat(value) > (tokenInfo?.balance / (10 ** tokenInfo?.decimals))) overflow(true);
        else overflow(false);
        if (value * (10 ** tokenInfo?.decimals) > (tokenInfo?.allowance)) {
          const token = approveToken.length > 0 ? approveToken?.filter((token) => token.symbol !== tokenInfo?.symbol) : [];
          setApprove([...token, tokenInfo]);
        } else setApprove([]);
      }
    }
  }, [web3Data.connected]);

  const handleInput = (value) => {
    if (value * (10 ** tokenInfo?.decimals) > (tokenInfo?.allowance)) {
      const token = approveToken.length > 0 ? approveToken?.filter((token) => token.symbol !== tokenInfo?.symbol) : [];
      console.log(token, approveToken);
      setApprove([...token, tokenInfo]);
    } else setApprove([]);
    handleAmount(value);
  }

  return (
    <>
      <div className={direction === SWAP_POSITION.FROM ? "from-value" : "to-value"}>
        <h6>{direction === SWAP_POSITION.FROM ? "From:" : "To:"}</h6>
        <p>{`Balance: ${!tokenInfo ? parseFloat(0).toFixed(4) : parseFloat(tokenInfo.balance?.toString() / 10 ** tokenInfo.decimals).toFixed(4)
          }`}</p>
      </div>
      <div
        className={direction === SWAP_POSITION.FROM ? "from-input" : "to-input"}
        style={{ background: (!tokenInfo || loading) && "#4c4a4a" }}
      >
        <div className="inputWithButton">
          <input
            type="number"
            placeholder="0"
            min={0}
            value={value || ""}
            disabled={!tokenInfo || loading === true ? true : false}
            onChange={(e) => {
              direction === SWAP_POSITION.FROM
                ? handleInput(e.target.value)
                : handleInput(e.target.value);
            }}
          />
          {tokenInfo?.balance > 0 ? (
            <button
              onClick={() => {
                direction === SWAP_POSITION.FROM
                  ? handleAmount((tokenInfo?.balance / (10 ** tokenInfo?.decimals)))
                  : handleAmount((tokenInfo?.balance / (10 ** tokenInfo?.decimals)));
              }}
            >
              MAX
            </button>
          ) : (
            <></>
          )}
        </div>
        <button
          className={direction === SWAP_POSITION.FROM ? "from-token" : "to-token"}
          onClick={() => openModal(true)}
        >
          {loading && (
            <Oval
              height={16}
              width={16}
              color="#ffffff"
              wrapperStyle={{ marginRight: "8px" }}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="transparent"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          )}
          <TokenLogo width="26px" height="26px">
            {tokenInfo?.logoURI === undefined ? (
              <BiQuestionMark style={{ fontSize: "30px" }} />
            ) : (
              <img src={tokenInfo?.logoURI} alt="Token Logo"></img>
            )}
          </TokenLogo>
          <p>{tokenInfo ? tokenInfo.symbol : ""}</p>
          <MdKeyboardArrowDown style={{ color: "#fff" }} />
        </button>
      </div>

      <ModalWrap isOpen={isOpen} cbClose={closeModal}>
        <header className="modal-header">
          <div className="flex flex-row justify-between items-center">
            <h6>Select a Token</h6>
            <button onClick={closeModal}>
              <MdClose style={{ width: "16px", height: "16px", color: "#fff" }} />
            </button>
          </div>
        </header>
        <div className="modal-body" style={{ height: "70vh" }}>
          <div className="token-list">
            {web3Data.chainId === BSC_Mainnet
              ? mainnetTokens.map((token, index) => (
                <TokenCard
                  key={index}
                  tokenInfo={token}
                  handleToken={(selectToken) => {
                    handleToken(selectToken);
                    closeModal();
                  }}
                />
              ))
              : mainnetTokens.map((token, index) => (
                <TokenCard
                  key={index}
                  tokenInfo={token}
                  handleToken={(token) => {
                    handleToken(token);
                    closeModal();
                  }}
                />
              ))}
          </div>
          <button className="manage-token">Manage Token</button>
        </div>
      </ModalWrap>
    </>
  );
}
