import AeternaLogo from "../assets/images/common/logo.png";

export const testnetTokens = [
  {
    name: "BNB Testnet",
    symbol: "tBNB",
    address: "0x9d70a3EE3079A6FA2bB16591414678b7Ad91f0b5",
    chainId: 97,
    decimals: 18,
    logoURI: "https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png",
  },
  {
    name: "AETERNA V2",
    symbol: "$Aeterna",
    address: "0x17b3Ca84E2abd683f05eA48ccd47BbCaB6AFCfED",
    chainId: 56,
    decimals: 9,
    logoURI: AeternaLogo,
  },
  // {
  //   name: "Wrapped BNB",
  //   symbol: "WBNB",
  //   address: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
  //   chainId: 97,
  //   decimals: 18,
  //   logoURI: "https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png",
  // },
  // {
  //   name: "BUSD Testnet",
  //   symbol: "BUSD",
  //   address: "0xd67c3E80492a6De1a45D255b670f75a6Eb0850BA",
  //   chainId: 97,
  //   decimals: 18,
  //   logoURI: "https://tokens.pancakeswap.finance/images/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png",
  // },
];

export const mainnetTokens = [
  {
    name: "BNB",
    symbol: "BNB",
    address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    chainId: 56,
    decimals: 18,
    logoURI: "https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png",
  },
  {
    name: "AETERNA V2",
    symbol: "$Aeterna",
    address: "0x17b3Ca84E2abd683f05eA48ccd47BbCaB6AFCfED",
    chainId: 56,
    decimals: 9,
    logoURI: AeternaLogo,
  },
];
