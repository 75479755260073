import { useContext, useEffect, useState } from "react";
import CoinStatsCard from "../components/CoinStatsCard";
import ComingSoon from "../components/Dashboard/ComingSoon";
import ComingBuyTicket from "../components/Dashboard/ComingBuyTicket";
import Exchange from "../components/Exchange";
import Staking from "../assets/images/dashboard/staking.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { web3ModalContext } from "../components/Web3ModalProvider";
import { useScrollTrigger } from "@mui/material";

export default function Dashboard() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract, getBalance, getFormatUnits, getFormatEther, getBignumberFrom, getBlockTimestamp, tokenPrice, marketCap, supply, holders, loadingTokenInfo } = useContext(web3ModalContext);

  return (
    <div className="container mx-auto">
      <div className="dashboard">
        <div className="coin-stats">
          <div className="grid xl:grid-cols-4 grid-flow-row">
            <CoinStatsCard
              loading={loadingTokenInfo}
              className="marketcap"
              title="Marketcap"
              value={marketCap}
              convert={true}
              unit="$"
            />
            <CoinStatsCard
              loading={loadingTokenInfo}
              className="supply"
              title="Circulating Supply"
              value={supply}
              convert={true}
              unit=""
            />
            <CoinStatsCard
              loading={loadingTokenInfo}
              className="holder"
              title="Holders"
              value={holders}
              convert={true}
              unit=""
            />
            <CoinStatsCard
              loading={loadingTokenInfo}
              className="price"
              title="AETERNA PRICE"
              value={tokenPrice}
              unit="$"
            />
          </div>
        </div>

        <div className="ads grid grid-cols-3 gap-8 grid-flow-row">
          <div className="announce-staking col-span-3 xl:col-span-2">
            <div className="announce">
              <div className="title">ANNOUNCEMENT</div>
              <div className="announce-slide overflow-hidden w-full">
                <ComingSoon>
                  <ComingBuyTicket />
                </ComingSoon>
              </div>
            </div>
            <div className="staking">
              <img src={Staking} alt="Staking" />
            </div>
          </div>

          <div className="fiat col-span-3 xl:col-span-1">
            <div className="title">FIAT</div>
            <div className="fiat-body">
              <Exchange />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
