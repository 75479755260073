import { createContext, useState } from "react";

export const SwapSettingContext = createContext();

export default function SwapSettingProvider({ children }) {
  const [slippage, setSlippage] = useState(9);
  const [timeline, setTimeline] = useState(2);

  return (
    <SwapSettingContext.Provider
      value={{
        slippage,
        setSlippage,
        timeline,
        setTimeline
      }}
    >
      {children}
    </SwapSettingContext.Provider>
  )
}