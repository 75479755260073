import { useState, useEffect, useContext } from "react";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import getContractsAddress from "../../web3/contractsAddress";
import PancakeswapFactory from "../../web3/abi/PancakeswapFactory.json";
import PancakeswapRouter from "../../web3/abi/PancakeswapRouter.json";
import { MdArrowDownward } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import timestring from "timestring";
import { BsQuestionCircle } from "react-icons/bs";
import { web3ModalContext } from "../Web3ModalProvider";
import { notify } from "../../utils";
import SwapInput from "./SwapInput";
import { mainnetTokens } from "../../constant/tokens";
import tokenList from "../../web3/pancakeswap-top-15";
import Lp_abi from "../../web3/abi/Lp_abi.json";
import tokenAbi from "../../web3/abi/tokenAbi.json";
import AeternaLogo from "../../assets/images/common/logo.png";
import { BSCSCAN_EXPLORER, SWAP_POSITION, BSC_Mainnet, BSC_Testnet, MAX_NUMBER } from "../../config";
import { SwapSettingContext } from "../SwapSettingProvider";
import { Link } from "react-router-dom";

export default function SwapBody() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract, getBalance,
    getFormatUnits, getFormatEther, getBignumberFrom, getBlockTimestamp } = useContext(web3ModalContext);
  const { slippage, timeline } = useContext(SwapSettingContext);
  const routerAddress = getContractsAddress(web3Data.chainId).PancakeswapRouter;

  const { liquidity } = location.state || {};
  const [currency0Address, setCurrency0Address] = useState(mainnetTokens[0].address);
  const [currency1Address, setCurrency1Address] = useState(web3Data.chainId == BSC_Mainnet ? getContractsAddress(BSC_Mainnet).tokenAddress : getContractsAddress(BSC_Testnet).tokenAddress);
  const [currency1, setCurrency1] = useState(null);
  const [currency0, setCurrency0] = useState(null);
  const [currency0Amount, setCurrency0Amount] = useState(0);
  const [currency1Amount, setCurrency1Amount] = useState(0);
  const [share0, setShare0] = useState(0);
  const [share1, setShare1] = useState(0);
  const [swapLoading, setSwapLoading] = useState(false);
  const [currency0Loading, setCurrency0Loading] = useState(false);
  const [currency1Loading, setCurrency1Loading] = useState(false);
  const [addLiq, setAddLiq] = useState(false);
  const [token0Overflow, setToken0Overflow] = useState(false);
  const [token1Overflow, setToken1Overflow] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [approveToken, setApproveToken] = useState([]);
  const [direction, setDirection] = useState(SWAP_POSITION.FROM);
  const [receivedMin, setReceivedMin] = useState(0);
  const [receivedMax, setReceivedMax] = useState(0);
  const [reserve, setReverse] = useState();
  const [priceImpact, setPriceImapct] = useState(0);

  const initTokens = () => {
    setCurrency0Address(mainnetTokens[0].address);
    setCurrency1Address(mainnetTokens[1].address);
    setCurrency1(null);
    setCurrency0(null);
    setCurrency0Amount(0);
    setCurrency1Amount(0);
    setApproveToken([]);
    setDirection(SWAP_POSITION.FROM);
  };

  const swapToken = async () => {
    setCurrency1Address(currency0Address);
    setCurrency0Address(currency1Address);
    setCurrency0Amount(0);
    setCurrency1Amount(0);
    await getPair(currency0Address, currency1Address);
  };

  const getBNBBalance = async () => {
    const balance = await getBalance();
    return balance;
  };

  const getTokenInfo = async (address) => {
    try {
      const tokenContract = getContract(address, tokenAbi);
      const tokenBalance = await tokenContract.balanceOf(web3Data.account);
      const tokenTotalSupply = await tokenContract.totalSupply();
      const tokenSymbol = await tokenContract.symbol();
      const tokenName = await tokenContract.name();
      const tokenDecimals = await tokenContract.decimals();
      const tokenAllowance = await tokenContract.allowance(web3Data.account, routerAddress);
      let tokenLogo = undefined;
      console.log("debug--------", address);

      if (web3Data.chainId === BSC_Mainnet) {
        const token = mainnetTokens.filter((token) => token.address === address);
        console.log("filter-----", token);
        tokenLogo = token[0].logoURI;
      } else if (web3Data.chainId === BSC_Testnet) {
        const token = mainnetTokens.filter((token) => token.address === address);
        tokenLogo = token[0].logoURI;
      }

      return {
        contract: tokenContract,
        address: address,
        name: tokenName,
        symbol: tokenSymbol,
        balance: tokenBalance,
        decimals: tokenDecimals,
        totalSupply: tokenTotalSupply,
        allowance: tokenAllowance,
        logoURI: tokenLogo,
      };
    } catch (err) {
      console.log("getTokenInfo is failed for " + address + ".\n", err);
    }
  };

  const getToken = async (address, pos) => {
    pos === SWAP_POSITION.FROM ? setCurrency0Loading(true) : setCurrency1Loading(true);
    try {
      if (address === mainnetTokens[0].address) {
        // BNB
        const balance = await getBNBBalance();
        const currency = {
          name: mainnetTokens[0].name,
          symbol: mainnetTokens[0].symbol,
          balance: balance,
          decimals: mainnetTokens[0].decimals,
          address: mainnetTokens[0].address,
          totalSupply: undefined,
          logoURI: mainnetTokens[0].logoURI,
        };
        pos === SWAP_POSITION.FROM ? setCurrency0(currency) : setCurrency1(currency);
      } else {
        // Token
        const currency = await getTokenInfo(address);
        // console.log("=====>\n", currency.allowance.toString(), currency.balance.toString(), currency.allowance.toString() < currency.totalSupply.toString());
        pos === SWAP_POSITION.FROM ? setCurrency0(currency) : setCurrency1(currency);
      }
    } catch (err) {
      console.log(`getToken by ${pos === SWAP_POSITION.FROM ? "FROM" : "TO"} is failed.\n`, err);
      pos === SWAP_POSITION.FROM ? setCurrency0Loading(false) : setCurrency1Loading(false);
    }
    pos === SWAP_POSITION.FROM ? setCurrency0Loading(false) : setCurrency1Loading(false);
  };

  const getPair = async (address0, address1) => {
    try {
      let address00 = address0 === mainnetTokens[0].address ? getContractsAddress(web3Data.chainId).WBNBAddress : address0;
      let address11 = address1 === mainnetTokens[0].address ? getContractsAddress(web3Data.chainId).WBNBAddress : address1;

      const factoryContract = getContract(getContractsAddress(web3Data.chainId).PancakeswapFactory, PancakeswapFactory);
      // console.log(address00, address11, factoryContract,);
      const pairAddress = await factoryContract.getPair(address00, address11);
      const pairContract = getContract(pairAddress, Lp_abi);
      const lpBalance = await pairContract.balanceOf(web3Data.account);
      const lpTotalSupply = await pairContract.totalSupply();
      const poolShare = parseFloat((lpBalance / lpTotalSupply) * 100).toFixed(2);
      const token0 = await getTokenInfo(await pairContract.token0());
      const token1 = await getTokenInfo(await pairContract.token1());

      const reserve = await pairContract.getReserves();

      const share0 = address0 == token0.address ?
        (reserve.reserve0?._hex / (10 ** token0.decimals)) / (reserve.reserve1?._hex / (10 ** token1.decimals)) :
        (reserve.reserve1?._hex / (10 ** token1.decimals)) / (reserve.reserve0?._hex / (10 ** token0.decimals));
      const share1 = address1 == token1.address ?
        (reserve.reserve1?._hex / (10 ** token1.decimals)) / (reserve.reserve0?._hex / (10 ** token0.decimals)) :
        (reserve.reserve0?._hex / (10 ** token0.decimals)) / (reserve.reserve1?._hex / (10 ** token1.decimals));

      console.log(
        share0, share1
      )
      setShare0(share0.toFixed(8));
      setShare1(share1.toFixed(8));
      setReverse({ reserve: reserve, token0: token0, token1: token1 });

      console.log(reserve.reserve0.toString(), reserve.reserve1.toString(), token0, token1);
      return {
        pairAddress: pairAddress,
        lpBalance,
        lpTotalSupply,
        reserve,
        token0,
        token1,
        poolShare,
        share0,
        share1
      };
    } catch (err) {
      setAddLiq(true);
      console.log("getPair is failed.\n", err);
    }
  };

  const swapETH4Aeterna = async (contract, path, amount0, amount1, deadline) => {
    let result = {};
    const timestamp = await getBlockTimestamp() + timestring(deadline + 'm');

    if (direction === SWAP_POSITION.FROM) {
      const amount1Min = await handle0Amount(amount0, currency0.decimals, currency1.decimals); // token2ETH = positive
      // setReceivedMin(amount1Min * (1 - slippage / 100) / (10 ** currency1.decimals));
      result = await contract.swapExactETHForTokensSupportingFeeOnTransferTokens(
        // complete
        getBignumberFrom(amount1Min)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp,
        { value: getFormatUnits(parseFloat(amount0).toFixed(18).toString()) }
      );
    } else if (direction === SWAP_POSITION.TO) {
      // complete
      const amount0Max = await handle1Amount(amount1, currency1.decimals, currency0.decimals);
      // setReceivedMax((amount0Max * (1 + slippage / 100) / (10 ** currency0.decimals)));
      result = await contract.swapETHForExactTokens(
        parseInt(amount1 * (10 ** currency1.decimals)).toString(),
        path,
        web3Data.account,
        timestamp,
        { value: (amount0Max * (1 + slippage / 100)).toString() }
      );
    }
    await result.wait();
    return result;
  };

  const swapAeterna4ETH = async (contract, path, amount0, amount1, deadline) => {
    try {
      let result = {};
      const timestamp = await getBlockTimestamp() + timestring(deadline + 'm');
      if (direction === SWAP_POSITION.FROM) {
        const amount1Min = await handle0Amount(amount0, currency0.decimals, currency1.decimals);
        result = await contract.swapExactTokensForETHSupportingFeeOnTransferTokens(
          // complete
          parseInt(amount0 * (10 ** currency0.decimals)).toString(),
          (parseInt(amount1Min * (1 - slippage / 100))).toString(),
          path,
          web3Data.account,
          timestamp
        );
      } else if (direction === SWAP_POSITION.TO) {
        // complete
        const amount0Max = await handle1Amount(amount1, currency1.decimals, currency0.decimals);
        // setReceivedMax((amount0Max * (1 + slippage / 100) / (10 ** currency0.decimals)));
        result = await contract.swapTokensForExactETH(
          getFormatUnits(amount1).toString(),
          (parseInt(amount0Max * (1 + slippage / 100)).toString()),
          path,
          web3Data.account,
          timestamp
        );
      }
      await result.wait();
      return result;
    } catch (err) {
      console.log(err);
      if (err.message.indexOf("Pancake: K") > 0) {
        toast.error("Unknown error: \"\Execustion reverted with reason: Insufficient WETH9.\"\.\n Try increasing your slippage tolerance.");
      }
    }
  };

  const swapAeterna4Token = async (contract, path, amount0, amount1, deadline) => {
    try {
      let result = {};
      const timestamp = await getBlockTimestamp() + timestring(deadline + 'm');

      if (direction === SWAP_POSITION.FROM) {
        const amount1Min = await handle0Amount(amount0, currency0.decimals, currency1.decimals);
        result = await contract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
          // todo
          (parseInt(amount0 * (10 ** currency0.decimals)).toString()).toString(),
          (parseInt(amount1Min * (1 - slippage / 100)).toString()),
          path,
          web3Data.account,
          timestamp
        );
      } else if (direction === SWAP_POSITION.TO) {
        // todo
        const amount0Min = await handle1Amount(amount1, currency1.decimals, currency0.decimals);
        result = await contract.swapTokensForExactTokens(
          (parseInt(amount1 * (10 ** currency1.decimals)).toString()).toString(),
          parseInt(amount0Min * (1 + slippage / 100)).toString(),
          path,
          web3Data.account,
          timestamp
        );
      }
      await result.wait();
      return result;
    } catch (err) {
      console.log(err);
      if (err.message.indexOf("Pancake: K") > 0) {
        toast.error("Unknown error: \"\Execustion reverted with reason: Insufficient token.\"\.\n Try increasing your slippage tolerance.");
      }
    }
  };

  const getTokens = async () => {
    try {
      if (currency0Address !== "") {
        await getToken(currency0Address, SWAP_POSITION.FROM);
      }
      if (currency1Address !== "") {
        await getToken(currency1Address, SWAP_POSITION.TO);
      }
      if (currency0Address !== "" && currency1Address !== "") {
        await getPair(currency0Address, currency1Address);
      }
    } catch (err) {
      console.log("getTokens is failed.\n", err);
    }
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getTokens();
    } else {
      initTokens();
    }
  }, [web3Data.connected, currency0Address, currency1Address]);

  const handleApprove = async () => {
    try {
      if (approveToken.length <= 0) {
        toast.error("approveToken is empty.");
        return;
      } else if (approveToken.length > 0) {
        setApproveLoading(true);
        for (let i = approveToken.length; i > 0; i--) {
          console.log(i)
          const approve = await approveToken[i - 1].contract.approve(routerAddress, MAX_NUMBER);
          await approve.wait();
          const symbol = approveToken[i - 1].symbol;
          const remoeToken = approveToken.pop();
          setApproveToken(remoeToken);
          notify({
            text: "Approve " + symbol,
            link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${approve.hash}`,
          });
        }
        setApproveLoading(false);
      }
    } catch (err) {
      console.log(err);
      notify({
        text: "Approve " + approveToken[0]?.symbol + " " + approveToken[0]?.symbol + "is failed.",
      });
      setApproveLoading(false);
    }
  };

  const handleSwap = async () => {
    setSwapLoading(true);
    try {
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      let result = {};

      if (currency0Address === mainnetTokens[0].address) {
        const path = [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address];
        result = await swapETH4Aeterna(routerContract, path, currency0Amount, currency1Amount, timeline);
      } else if (currency1Address === mainnetTokens[0].address) {
        const path = [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress];
        result = await swapAeterna4ETH(routerContract, path, currency0Amount, currency1Amount, timeline);
      } else {
        const path = [currency0Address, currency1Address];
        result = await swapAeterna4Token(routerContract, path, currency0Amount, currency1Amount, timeline);
      }

      setCurrency0Amount(0);
      setCurrency1Amount(0);
      notify({
        text: `Swap ${currency0Amount} ${currency0.symbol} for ${currency1Amount} ${currency1.symbol}`,
        link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${result.hash}`,
      });
      const timer = setTimeout(() => {
        getTokens();
      }, 4000);

      setSwapLoading(false);
    } catch (err) {
      setSwapLoading(false);
      console.log("handleSwap is failed.\n", err);
    }
  };

  // @param amount: input value
  // @param inDec: input's decimals
  // @param outDec: output's decimals
  // @returns aount1min: minimum amount
  const handle0Amount = async (amount, inDec, outDec) => {
    try {
      let amount1Min = 0;
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      if (amount !== undefined || amount !== "" || amount > 0) {
        setDirection(SWAP_POSITION.FROM);
        setCurrency0Amount(amount);

        const path =
          currency0Address === mainnetTokens[0].address
            ? [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address]
            : currency1Address === mainnetTokens[0].address
              ? [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress]
              : [currency0Address, currency1Address];
        amount1Min = (await routerContract.getAmountsOut((amount * (10 ** inDec)).toString(), path))[1];
        if ((amount * 10 ** inDec) > currency0?.balance) setOverflow(true)
        else setOverflow(false);
        setCurrency1Amount(parseFloat(amount1Min.toString() / 10 ** outDec).toFixed(6));
        setReceivedMin((amount1Min * (1 - slippage / 100) / (10 ** outDec)).toFixed(4));
        const impact = reserve.token0.address == currency0Address ?
          parseFloat((amount1Min / (reserve.reserve.reserve1?.toString() - amount1Min.toString()) * 100).toFixed(3)) :
          parseFloat((amount1Min / (reserve.reserve.reserve0?.toString() - amount1Min.toString()) * 100).toFixed(3))
        setPriceImapct(impact);

        return amount1Min;
      }
    } catch (err) {
      console.log("handle0Amount is failed.\n", err);
    }
  };

  const handle1Amount = async (amount, inDec, outDec) => {
    try {
      let amount0 = 0;
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      if (amount !== undefined || amount !== "" || amount > 0) {
        setDirection(SWAP_POSITION.TO);
        setCurrency1Amount(amount);

        const path =
          currency0Address === mainnetTokens[0].address
            ? [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address]
            : currency1Address === mainnetTokens[0].address
              ? [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress]
              : [currency0Address, currency1Address];
        amount0 = (await routerContract.getAmountsIn((amount * (10 ** inDec)).toString(), path))[0];
        if (amount0 > currency0?.balance) setOverflow(true)
        else setOverflow(false);
        setCurrency0Amount(parseFloat(amount0.toString() / (10 ** outDec)).toFixed(6));
        setReceivedMax((amount0 * (1 + slippage / 100) / (10 ** outDec)).toFixed(4));
        const impact = reserve.token0.address == currency0Address ?
          parseFloat(((amount * 10 ** inDec) / (reserve.reserve.reserve1?.toString() - (amount * 10 ** inDec).toString()) * 100).toFixed(3)) :
          parseFloat(((amount * 10 ** inDec) / (reserve.reserve.reserve0?.toString() - (amount * 10 ** inDec).toString()) * 100).toFixed(3));
        setPriceImapct(impact);

        return amount0;
      }
    } catch (err) {
      console.log("handle1Amount is failed.\n", err);
    }
  };

  return (
    <div className="swap-body">
      <div className="swap-from">
        <SwapInput
          tokenInfo={currency0}
          loading={currency0Loading}
          overflow={(flag) => setToken0Overflow(flag)}
          value={currency0Amount}
          direction={SWAP_POSITION.FROM}
          handleToken={(token) => {
            setCurrency0(token);
            setCurrency0Address(token.address);
          }}
          approveToken={approveToken}
          setApprove={(apporveTokens) => setApproveToken(apporveTokens)}
          handleAmount={async (amount) => currency0Address === mainnetTokens[0].address ? handle0Amount(amount, currency0.decimals, currency1.decimals) : handle0Amount(amount, currency0.decimals, currency1.decimals)}
        />
      </div>

      {/* exchange token button */}
      <button className="reverse-token" onClick={async () => await swapToken()}>
        <MdArrowDownward style={{ width: "24px", height: "24px", color: "#fff" }} />
      </button>

      <div className="swap-to">
        <SwapInput
          tokenInfo={currency1}
          loading={currency1Loading}
          overflow={(flag) => setToken1Overflow(flag)}
          value={currency1Amount}
          direction={SWAP_POSITION.TO}
          handleToken={(token) => {
            setCurrency1(token);
            setCurrency1Address(token.address);
          }}
          approveToken={approveToken}
          setApprove={(apporveTokens) => setApproveToken(apporveTokens)}
          handleAmount={async (amount) => currency0Address === mainnetTokens[0].address ? handle1Amount(amount, currency1.decimals, currency0.decimals) : handle1Amount(amount, currency1.decimals, currency0.decimals)}
        />
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-white text-base">Slippage Tolerance</p>
        <p className="text-sky-400">{slippage}%</p>
      </div>
      {web3Data.connected ? (
        currency0Amount > 0 ? (
          <div className="flex flex-row justify-between mb-2">
            <p className="text-white text-base">Price</p>
            <div className="flex flex-row justify-around items-center">
              <p className="text-white text-base">
                {
                  (currency0Loading || currency1Loading) ?
                    <Oval
                      height={16}
                      width={16}
                      color="#ffffff"
                      wrapperStyle={{ marginRight: "8px" }}
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="transparent"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    /> : <>{share0} {currency0?.symbol} Per {currency1?.symbol}</>
                }
              </p>
              {/* <TbRefresh
                style={{
                  width: "18px",
                  height: "18px",
                  color: "#fff",
                  marginLeft: "2px",
                }}
              /> */}
            </div>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {web3Data.connected ? (
        currency0Amount > 0 && currency1Amount > 0 ? (
          <>
            <div className="flex flex-row justify-between">
              {
                addLiq ?
                  <Link to="/exchange/liquidity/add" className="exchange-button">Add Liquidity</Link> :
                  overflow ?
                    <button
                      className="exchange-button"
                      disabled={overflow}
                    >
                      Insufficient Balance
                    </button> : <>
                      {
                        approveToken.length > 0 ? (
                          <button className="exchange-button mr-5" onClick={handleApprove}>
                            Enable {approveToken[0]?.symbol} {approveToken[1]?.symbol}
                          </button>
                        ) : (<></>)
                      }
                      <button className="exchange-button" disabled={swapLoading || currency0Amount > (currency0.balance / (10 ** currency0.decimals)) || priceImpact > 15} onClick={handleSwap}>
                        {swapLoading && (
                          <Oval
                            height={16}
                            width={16}
                            color="#ffffff"
                            wrapperStyle={{ marginRight: "8px" }}
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="transparent"
                            strokeWidth={4}
                            strokeWidthSecondary={4}
                          />
                        )}
                        {priceImpact > 15 ? 'Price Impact Too High' : 'Swap'}
                      </button>
                    </>
              }
            </div>
            <div className="swap-info">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center">
                  <p className="mr-2">{direction === SWAP_POSITION.FROM ? "Minimum received" : "Maximum sold"} </p>
                  <BsQuestionCircle />
                </div>
                <p>{direction === SWAP_POSITION.FROM ? receivedMin : receivedMax} {direction === SWAP_POSITION.FROM ? currency1.symbol : currency0.symbol}</p>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center">
                  <p className="mr-2">Price Impact</p>
                  <BsQuestionCircle />
                </div>
                <p className="text-sky-400">{priceImpact}%</p>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center">
                  <p className="mr-2">Trading Fee</p>
                  <BsQuestionCircle />
                </div>
                <p>{parseFloat((currency0Amount * 0.0025).toFixed(7))} {currency0.symbol}</p>
              </div>
              {/* <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center">
                  <p className="mr-2">Route</p>
                  <BsQuestionCircle />
                </div>
                <p>BNB {">"} AETERNA</p>
              </div> */}
            </div>
          </>
        ) : (
          <button className="enter-button">Enter an amount</button>
        )
      ) : (
        <button className="connect-button" onClick={connect}>
          Connect Wallet
        </button>
      )}
    </div>
  );
}
