import { CgChevronDoubleRightO } from "react-icons/cg";
import { CgChevronDoubleLeftO } from "react-icons/cg";
import { CgChevronRightO } from "react-icons/cg";
import { CgChevronLeftO } from "react-icons/cg";

export default function DatePagination(props) {
  return (
    <div className="date-pagination">
      <div className="pagination">
        <h3>Past Ticket</h3>
        <div className="prev-next flex justify-center items-center">
          <button>
            <CgChevronDoubleLeftO style={{ fontSize: "20px" }} />
          </button>
          <button>
            <CgChevronLeftO style={{ fontSize: "20px" }} />
          </button>
          <h3 className="mx-0 sm:mx-8">JUNE 2023</h3>
          <button>
            <CgChevronRightO style={{ fontSize: "20px" }} />
          </button>
          <button>
            <CgChevronDoubleRightO style={{ fontSize: "20px" }} />
          </button>
        </div>
      </div>
      {props.children}
    </div>
  );
}
