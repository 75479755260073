import { getFormatEther } from "../../../web3/getEthers";

export default function SharePrice({ reserve, token0, token1 }) {
  let outToken0 = 0;
  let outToken1 = 0;
  console.log(reserve.reserve0, reserve.reserve0?._hex);
  if (reserve.reserve0 !== undefined || reserve.reserve1 !== undefined) {
    outToken0 = (reserve.reserve0?._hex/(10**token0.decimals)) / (reserve.reserve1?._hex/(10**token1.decimals));
    outToken1 = (reserve.reserve1?._hex/(10**token1.decimals)) / (reserve.reserve0?._hex/(10**token0.decimals));
  }
  // console.log(reserve, token0, token1)
  return (
    <div className="share-price">
      {reserve.reserve0 !== undefined || reserve.reserve0 !== undefined ? (
        <>
          <p>{`${outToken1.toFixed(6)} ${token1?.symbol} per ${token0?.symbol}`}</p>
          <p>{`${outToken0.toFixed(6)} ${token0?.symbol} per ${token1?.symbol}`}</p>
        </>
      ) : (
        <p>No share price</p>
      )}
    </div>
  );
}
