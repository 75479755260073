import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import getContractsAddress from "../../web3/contractsAddress";
import Lp_abi from "../../web3/abi/Lp_abi.json";
import tokenAbi from "../../web3/abi/tokenAbi.json";
import PancakeswapRouter from "../../web3/abi/PancakeswapRouter.json";
import PancakeswapFactory from "../../web3/abi/PancakeswapFactory.json";
import { notify } from "../../utils";
import LiquidityHeader from "./component/LiquidityHeader";
import LiquidityBody from "./component/LiquidityBody";
import LiquidityInput from "./component/LiquidityInput";
import SharePrice from "./component/SharePrice";
import ModalWrap from "../Modal";
import { web3ModalContext } from "../Web3ModalProvider";
import { MdSettings, MdClose } from "react-icons/md";
import { BsPlusCircle } from "react-icons/bs";
import { RiErrorWarningLine } from "react-icons/ri";
import { mainnetTokens } from "../../constant/tokens";
import { SWAP_POSITION, MAX_NUMBER, BSC_Mainnet, BSC_Testnet } from "../../config";
import { SwapSettingContext } from "../SwapSettingProvider";
import timestring from "timestring";

export default function AddLiquidity() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract, getBalance,
    getFormatUnits, getBignumberFrom, getBlockTimestamp } = useContext(web3ModalContext);
  const { slippage, setSlippage, timeline, setTimeline } = useContext(SwapSettingContext);
  const routerAddress = getContractsAddress(web3Data.chainId).PancakeswapRouter;
  const location = useLocation();
  const { liquidity } = location.state || {};
  const [selected, setSelected] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [token0Address, setToken0Address] = useState(mainnetTokens[0].address);
  const [token1Address, setToken1Address] = useState(web3Data.chainId == BSC_Mainnet ? getContractsAddress(BSC_Mainnet).tokenAddress : getContractsAddress(BSC_Testnet).tokenAddress);
  const [token0, setToken0] = useState(null);
  const [token1, setToken1] = useState(null);
  const [token0Loading, setToken0Loading] = useState(false);
  const [token1Loading, setToken1Loading] = useState(false);
  const [reserve, setReserve] = useState({});
  const [token0Share, setToken0Share] = useState(0);
  const [token1Share, setToken1Share] = useState(0);
  const [approveToken, setApproveToken] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [token0Amount, setToken0Amount] = useState(0);
  const [token1Amount, setToken1Amount] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [successConfirm, setSuccessConfirm] = useState(false);
  const [token0Overflow, setToken0Overflow] = useState(false);
  const [token1Overflow, setToken1Overflow] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    if (slippage > 49) setSlippage(49);
    else if (slippage <= 0) setSlippage(0.1);
    if (timeline < 1) setTimeline(2);
    setIsOpen(false);
  }

  const selectSlippage = (percent, selected) => {
    setSlippage(percent);
    setSelected(selected);
  };

  const getTokenInfo = async (address) => {
    try {
      const tokenContract = getContract(address, tokenAbi);
      const tokenBalance = await tokenContract.balanceOf(web3Data.account);
      const tokenTotalSupply = await tokenContract.totalSupply();
      const tokenSymbol = await tokenContract.symbol();
      const tokenName = await tokenContract.name();
      const tokenDecimals = await tokenContract.decimals();
      const tokenAllowance = await tokenContract.allowance(web3Data.account, routerAddress);
      const tokenLogoURI = mainnetTokens.filter((token) => token.symbol === tokenSymbol)[0].logoURI;

      return {
        address: address,
        contract: tokenContract,
        name: tokenName,
        symbol: tokenSymbol,
        balance: tokenBalance,
        decimals: tokenDecimals,
        totalSupply: tokenTotalSupply,
        allowance: tokenAllowance,
        logoURI: tokenLogoURI,
      };
    } catch (err) {
      console.log("useEffect getTokenInfo is failed for " + address + ".\n", err);
    }
  };

  const getBNBBalance = async () => {
    const balance = await getBalance();
    return balance;
  };

  const getPair = async (address) => {
    try {
      const pairContract = getContract(address, Lp_abi);
      const lpBalance = await pairContract.balanceOf(web3Data.account);
      const lpTotalSupply = await pairContract.totalSupply();
      const poolShare = parseFloat((lpBalance / lpTotalSupply) * 100).toFixed(2);
      const token0 = await getTokenInfo(await pairContract.token0());
      const token1 = await getTokenInfo(await pairContract.token1());
      const reserve = await pairContract.getReserves();

      return {
        pairAddress: address,
        lpBalance,
        lpTotalSupply,
        reserve,
        token0,
        token1,
        poolShare,
      };
    } catch (err) {
      console.log("useEffect getPair is failed.\n", err);
    }
  };

  const getToken = async (address, pos) => {
    try {
      if (address === mainnetTokens[0].address) {
        // BNB
        const balance = await getBNBBalance();
        const token = {
          name: mainnetTokens[0].name,
          symbol: mainnetTokens[0].symbol,
          balance: balance,
          decimals: mainnetTokens[0].decimals,
          address: mainnetTokens[0].address,
          totalSupply: 0,
          logoURI: mainnetTokens[0].logoURI,
        };
        pos === SWAP_POSITION.FROM ? setToken0(token) : setToken1(token);
      } else {
        const token = await getTokenInfo(address);
        if (pos === SWAP_POSITION.FROM) {
          setToken0(token);
        } else {
          setToken1(token);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTokens = async () => {
    // console.log(token0Address, token1Address);
    try {
      if (token0Address !== "") {
        setToken0Loading(true);
        await getToken(token0Address, SWAP_POSITION.FROM);
      }
      if (token1Address !== "") {
        setToken1Loading(true);
        await getToken(token1Address, SWAP_POSITION.TO);
      }
      if (token0Address !== "" && token1Address !== "") {
        const factoryContract = getContract(getContractsAddress(web3Data.chainId).PancakeswapFactory, PancakeswapFactory);
        let pairAdd = "";
        let liquidity = {};
        if (token0Address === mainnetTokens[0].address) {
          // BNB
          pairAdd = await factoryContract.getPair(getContractsAddress(web3Data.chainId).WBNBAddress, token1Address);
          if (pairAdd) {
            liquidity = await getPair(pairAdd);
            if (liquidity.token0.address === getContractsAddress(web3Data.chainId).WBNBAddress) {
              liquidity.token0.address = mainnetTokens[0].address;
              liquidity.token0.symbol = mainnetTokens[0].symbol;
              liquidity.token0.name = mainnetTokens[0].name;
              liquidity.token0.logoURI = mainnetTokens[0].logoURI;
              liquidity.token0.decimals = mainnetTokens[0].decimals;
              liquidity.token0.balance = await getBNBBalance();
            } else if (liquidity.token1.address === getContractsAddress(web3Data.chainId).WBNBAddress) {
              liquidity.token1.address = mainnetTokens[0].address;
              liquidity.token1.symbol = mainnetTokens[0].symbol;
              liquidity.token1.name = mainnetTokens[0].name;
              liquidity.token1.decimals = mainnetTokens[0].decimals;
              liquidity.token1.logoURI = mainnetTokens[0].logoURI;
              liquidity.token1.balance = await getBNBBalance();
            }
          }
        } else if (token1Address === mainnetTokens[0].address) {
          // BNB
          pairAdd = await factoryContract.getPair(token0Address, getContractsAddress(web3Data.chainId).WBNBAddress);
          if (pairAdd) {
            liquidity = await getPair(pairAdd);
            if (liquidity.token0.address === getContractsAddress(web3Data.chainId).WBNBAddress) {
              liquidity.token0.address = mainnetTokens[0].address;
              liquidity.token0.symbol = mainnetTokens[0].symbol;
              liquidity.token0.name = mainnetTokens[0].name;
              liquidity.token0.logoURI = mainnetTokens[0].logoURI;
              liquidity.token0.decimals = mainnetTokens[0].decimals;
              liquidity.token0.balance = await getBNBBalance();
            } else if (liquidity.token1.address === getContractsAddress(web3Data.chainId).WBNBAddress) {
              liquidity.token1.address = mainnetTokens[0].address;
              liquidity.token1.symbol = mainnetTokens[0].symbol;
              liquidity.token1.name = mainnetTokens[0].name;
              liquidity.token1.logoURI = mainnetTokens[0].logoURI;
              liquidity.token1.decimals = mainnetTokens[0].decimals;
              liquidity.token1.balance = await getBNBBalance();
            }
          }
        } else {
          pairAdd = await factoryContract.getPair(token0Address, token1Address);
          if (pairAdd) {
            liquidity = await getPair(pairAdd);
          }
        }
        handleLiquidity(liquidity);
      }
      setToken0Loading(false);
      setToken1Loading(false);
    } catch (err) {
      console.log(err);
      if (token0Address !== "") {
        setToken0Loading(false);
      }
      if (token1Address !== "") {
        setToken1Loading(false);
      }
    }
  };

  const handleLiquidity = (liquidity) => {
    if (liquidity !== undefined) {
      setToken0Loading(true);
      setToken1Loading(true);
      setToken0Address(liquidity.token0.address);
      setToken1Address(liquidity.token1.address);
      const token0Contract = getContract(liquidity.token0.address, tokenAbi);
      const token1Contract = getContract(liquidity.token1.address, tokenAbi);
      setToken0({ ...liquidity.token0, contract: token0Contract });
      setToken1({ ...liquidity.token1, contract: token1Contract });
      setReserve(liquidity.reserve);
      const token0Share =
        (liquidity.reserve.reserve0?._hex / (10 ** liquidity.token0.decimals)) /
        (liquidity.reserve.reserve1?._hex / (10 ** liquidity.token1.decimals));
      const token1Share =
        (liquidity.reserve.reserve1?._hex / (10 ** liquidity.token1.decimals)) /
        (liquidity.reserve.reserve0?._hex / (10 ** liquidity.token0.decimals));
      setToken0Share(token0Share);
      setToken1Share(token1Share);
      setToken0Loading(false);
      setToken1Loading(false);
    } else if (liquidity == undefined) {
      setReserve({});
      setToken0Share(0);
      setToken1Share(0);
    }
  };

  const handleApprove = async () => {
    if (approveToken.length > 0) {
      setApproveLoading(true);
      for (let i = approveToken.length - 1; i => 0; i--) {
        const approve = await approveToken[i].contract.approve(routerAddress, MAX_NUMBER);
        await approve.wait();
        const symbol = approveToken[i].symbol;
        const remoeToken = approveToken.pop();
        console.log(remoeToken);
        setApproveToken(remoeToken);
        notify({
          text: "Approve " + symbol,
          link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${approve.hash}`,
        });
      }
      setApproveLoading(false);
    }
  };

  const handleConform = async () => {
    try {
      setConfirmLoading(true);
      const timestamp = await getBlockTimestamp() + timestring(timeline + 'm');
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      let addedLiquidity = {};
      if (token0Address === mainnetTokens[0].address) {
        addedLiquidity = await routerContract.addLiquidityETH(
          token1Address,
          (parseInt(token1Amount * (10 ** token1.decimals))).toString(),
          (parseInt(token1Amount * (10 ** token1.decimals) * (1 - slippage / 100))).toString(),
          (parseInt(token0Amount * (10 ** token0.decimals) * (1 - slippage / 100))).toString(),
          web3Data.account,
          timestamp,
          { value: parseInt(token0Amount * (10 ** token0.decimals)).toString() }
        );
      } else if (token1Address === mainnetTokens[0].address) {
        addedLiquidity = await routerContract.addLiquidityETH(
          token0Address,
          (parseInt(token0Amount * (10 ** token0.decimals))).toString(),
          (parseInt(token0Amount * (10 ** token0.decimals) * (1 - slippage / 100))).toString(),
          (parseInt(token1Amount * (10 ** token1.decimals) * (1 - slippage / 100))).toString(),
          web3Data.account,
          timestamp,
          { value: parseInt(token1Amount * (10 ** token1.decimals)).toString() }
        );
      } else {
        addedLiquidity = await routerContract.addLiquidity(
          token0Address,
          token1Address,
          (parseInt(token0Amount * (10 ** token0.decimals)).toString()),
          (parseInt(token1Amount * (10 ** token1.decimals)).toString()),
          (parseInt(token0Amount * (10 ** token0.decimals) * (1 - slippage / 100)).toString()),
          (parseInt(token1Amount * (10 ** token1.decimals) * (1 - slippage / 100)).toString()),
          web3Data.account,
          timestamp
        );
      }

      await addedLiquidity.wait();
      setToken0Amount(0);
      setToken1Amount(0);
      setConfirmLoading(false);
      setSuccessConfirm(true);
      notify({
        text: `Add ${parseFloat(token0Amount).toFixed(4)} ${token0.symbol} and ${parseFloat(token1Amount).toFixed(4)} ${token1.symbol
          }`,
        link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${addedLiquidity.hash}`,
      });
      console.log(token0.contract);
    } catch (err) {
      console.log(err);
      setConfirmLoading(false);
    }
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) {
        if (liquidity !== undefined) {
          console.log("liquidity", liquidity);
          handleLiquidity(liquidity);
        } else {
          getTokens();
        }
      }
    }
  }, [web3Data.connected, token0Address, token1Address]);

  return (
    <>
      <LiquidityHeader className="add-liquidity-header">
        <div className="flex flex-row justify-between mb-1">
          <p>Add Liquidity</p>
          <button className="liquidity-setting" onClick={openModal}>
            <MdSettings style={{ width: "24px", height: "24px", color: "#fff" }} />
          </button>
        </div>
      </LiquidityHeader>
      <LiquidityBody>
        <div className="liquidity-inputs">
          <LiquidityInput
            tokenInfo={token0}
            loading={token0Loading}
            successConfirm={successConfirm}
            overflow={(flag) => setToken0Overflow(flag)}
            handleToken={(token) => {
              setToken0(token);
              setToken0Address(token.address);
            }}
            approveToken={approveToken}
            setApprove={(apporveTokens) => setApproveToken(apporveTokens)}
            handleValue={(value) => {
              setToken0Amount(value);
              if (token1Share > 0) {
                setToken1Amount(token1Share * value);
              }
            }}
            value={token0Amount}
          />
          <BsPlusCircle
            style={{
              width: "45px",
              height: "45px",
              margin: "8px 0",
              color: "#707070",
            }}
          />
          <LiquidityInput
            tokenInfo={token1}
            loading={token1Loading}
            successConfirm={successConfirm}
            overflow={(flag) => setToken1Overflow(flag)}
            handleToken={(token) => {
              setToken1(token);
              setToken1Address(token.address);
            }}
            approveToken={approveToken}
            setApprove={(apporveTokens) => setApproveToken(apporveTokens)}
            handleValue={(value) => {
              token1.name && setToken1Amount(value);
              if (token0Share > 0) {
                setToken0Amount(token0Share * value);
              }
            }}
            value={token1Amount}
          />
        </div>
        {token0Overflow === true || token1Overflow === true ? (
          <div className="overflow">
            <RiErrorWarningLine style={{ fontSize: "32px", marginRight: "10px" }} />
            <p>
              Price Impact Too High.{" "}
              <strong>{`Reduce amount of ${token0Overflow === true ? token0.symbol : ""}
            ${token1Overflow === true ? " " + token1.symbol : ""} to maximum limit`}</strong>
            </p>
          </div>
        ) : (
          <></>
        )}
        <SharePrice reserve={reserve} token0={token0} token1={token1} />
        <div className="p-4">
          {token0Amount <= 0 && token1Amount <= 0 ? (
            <button className="enter-liquidity-button">Enter an amount</button>
          ) : token0Overflow === true || token1Overflow === true ?
            <button className="enter-liquidity-button">
              Insufficient {token0Overflow === true ? " " + token0.symbol : ""} {token1Overflow === true ? " " + token1.symbol : ""} balance
            </button>
            : approveToken.length > 0 ? (
              <button className="approve-liquidity-button" onClick={handleApprove}>
                {approveLoading === true || token0Loading === true || token1Loading === true ? (
                  <Oval
                    height={16}
                    width={16}
                    color="#ffffff"
                    wrapperStyle={{ marginRight: "8px" }}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="transparent"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                ) : (
                  <></>
                )}
                {token0Loading === true || token1Loading === true ? "Approve" : "Approving"} {approveToken[0]?.symbol}{" "}
                {approveToken[1]?.symbol}
              </button>
            ) : (
              <button
                className={`confirm-liquidity-button ${token0Overflow === true || token1Overflow === true ? "insufficient" : ""
                  }`}
                onClick={handleConform}
                disabled={confirmLoading || token0Overflow || token1Overflow}
              >
                {confirmLoading === true ? (
                  <Oval
                    height={16}
                    width={16}
                    color="#ffffff"
                    wrapperStyle={{ marginRight: "8px" }}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="transparent"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                ) : (
                  <></>
                )}
                {token0Overflow === true || token1Overflow === true
                  ? `Insufficient${token0Overflow === true ? " " + token0.symbol : ""}${token1Overflow === true ? " " + token1.symbol : ""
                  } balance`
                  : "Confirm Adding Liquidity"}
              </button>
            )}
        </div>
      </LiquidityBody>
      <ModalWrap isOpen={isOpen} cbClose={closeModal}>
        <header className="modal-header">
          <div className="flex flex-row justify-between items-center">
            <h6>Setting</h6>
            <button onClick={closeModal}>
              <MdClose style={{ width: "16px", height: "16px", color: "#fff" }} />
            </button>
          </div>
        </header>
        <div className="modal-body">
          <div className="setting">
            <div className="slippage">
              <h4>Slippage Tolerlance</h4>
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <button className={`percent ${selected == 0 ? "active" : ""}`} onClick={() => selectSlippage(0.1, 0)}>
                  0.1%
                </button>
                <button className={`percent ${selected == 1 ? "active" : ""}`} onClick={() => selectSlippage(0.5, 1)}>
                  0.5%
                </button>
                <button className={`percent ${selected == 2 ? "active" : ""}`} onClick={() => selectSlippage(1, 2)}>
                  1.0%
                </button>
                <button className={`percent ${selected == 3 ? "active" : ""}`} onClick={() => selectSlippage(5, 3)}>
                  5.0%
                </button>
                <input
                  type="number"
                  className="percent custom"
                  placeholder="Input slippage"
                  value={slippage}
                  max={49}
                  onChange={e => selectSlippage(e.target.value, -1)}
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <h4 className="text-xl font-medium">Tx deadline (mins)</h4>
              <div className="">
                <input
                  type="number"
                  className="w-20 bg-[#121425] rounded-2xl font-medium"
                  placeholder="20"
                  value={timeline}
                  onChange={e => setTimeline(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalWrap>
    </>
  );
}
