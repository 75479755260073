import Slider from "react-slick";
import Moment from "react-moment";
import Ticket from "./Ticket";

export default function TicketListCarousel({ tickets }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "30px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "30px",
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerPadding: "30px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  console.log(tickets);
  return (
    <div className="ticket-list-carousel">
      <h4>Round {tickets.id}</h4>
      <p className="mb-4">
        <Moment format="MMM D, YYYY">{tickets.startTime * 1000}</Moment>
      </p>
      {tickets.tickets.length > 3 ? (
        <Slider {...settings}>
          {tickets.tickets.map((ticket, index) => (
            <Ticket key={index} pickedNumber={ticket.numbers} />
          ))}
        </Slider>
      ) : 0 < tickets.tickets.length && tickets.tickets.length <= 3 ? (
        <div className="flex justify-between items-center">
          {tickets.tickets.map((ticket, index) => (
            <Ticket key={index} pickedNumber={ticket.numbers} />
          ))}
        </div>
      ) : (
        <h3>No tickets</h3>
      )}
    </div>
  );
}
