import { BSC_Mainnet, BSC_Testnet } from "../config";

const getContractsAddress = (networkId) => {
  switch (networkId) {
    case BSC_Mainnet:
      return {
        escrowAddress: "0xB85D2504BB6fF4580Bd337744Bcea8b2d3B7eD65",
        PancakeswapRouter: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
        PancakeswapFactory: "0xca143ce32fe78f1f7019d7d551a6402fc5350c73",
        WBNBAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
        PoolAddress: "0x8FEdD8cB7A02107aA376E320B066350Ad9fd4A0e",
        LottryAddress: "0x736018c91667F4606c2f3Db8a8C3C6aFCD33c95E",
        tokenAddress: "0x17b3Ca84E2abd683f05eA48ccd47BbCaB6AFCfED",
        presaleAddress: "0xb2fc127d4f40807D6b6c590bcAf537c8A8f2E1E9",
      };
    case BSC_Testnet:
      return {
        escrowAddress: "0x81e206F92d237Dc849353F6E15513b8D88ea451B",
        PancakeswapRouter: "0x7F3Ae476dcB6bee7dAb5E756Aef11BFcfd56Db16",
        PancakeswapFactory: "0x9b1b8776152345ce0914Cd865F830b9eCc64c572",
        WBNBAddress: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
        PoolAddress: "0x0E54a5f87e472003762eD5eC2c4e4ab87B7fED7b",
        LottryAddress: "0xFC5c779113EE834C9D4e01E36551F84bAD56e94c",
        tokenAddress: "0x3D44C63C01f61744B4D599Ffaa5CE16eE9F14A99",
        presaleAddress: "0xb2fc127d4f40807D6b6c590bcAf537c8A8f2E1E9",
      };
    default:
      return "0xC9Ad2F68059dFeB39DBb00A867ebB1f9b782f353";
  }
};

export default getContractsAddress;
