import { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Lp_abi from '../../web3/abi/Lp_abi.json';
import token_abi from '../../web3/abi/tokenAbi.json';
import axios from 'axios';

export const web3ModalContext = createContext();

export default function Web3ModalProvider({ children }) {
  const LP_ADDRESS = "0xE0351D9c8D2850759f3684c48cd4d53904aB2668";

  const INITIAL_STATE = {
    fetching: false,
    account: "",
    provider: null,
    library: null,
    signer: null,
    connected: false,
    network: 1,
    chainId: 1,
    contract: null,
  };
  const [web3Modal, setWeb3Modal] = useState(null);
  const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [marketCap, setMarketCap] = useState(0);
  const [supply, setSupply] = useState(0);
  const [holders, setHolders] = useState(0);
  const [web3Data, setWeb3Data] = useState({ ...INITIAL_STATE });

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        network: "binance",
        rpc: process.env.REACT_APP_RPC_URL,
        chainId: web3Data.chainId,
      },
    },
    coinbasewallet: {
      package: CoinbaseWalletSDK, // Required
      options: {
        appName: "AETERNA Dapp", // Required
        infuraId: "", // Required
        rpc: process.env.REACT_APP_RPC_URL, // Optional if `infuraId` is provided; otherwise it's required
        chainId: web3Data.chainId, // Optional. It defaults to 1 if not provided
        darkMode: true // Optional. Use dark theme, defaults to false
      }
    },
    "custom-binancechainwallet": {
      display: {
        logo: "https://lh3.googleusercontent.com/rs95LiHzLXNbJdlPYwQaeDaR_-2P9vMLBPwaKWaQ3h9jNU7TOYhEz72y95VidH_hUBqGXeia-X8fLtpE8Zfnvkwa=w128-h128-e365-rj-sc0x00ffffff",
        name: "Binance Chain Wallet",
        darkMode: true,
        description: "Connect to your Binance Chain Wallet"
      },
      package: true,
      connector: async () => {
        let provider = null;
        if (typeof window.BinanceChain !== 'undefined') {
          provider = window.BinanceChain;
          try {
            const account = await provider.request({ method: 'eth_requestAccounts' })
            console.log(account[0]);
          } catch (error) {
            throw new Error("User Rejected");
          }
        } else {
          throw new Error("No Binance Chain Wallet found");
        }
        return provider;
      }
    },
  };

  const connectWallet = async () => {
    const web3Modal = new Web3Modal({
      network: "Binance",
      cacheProvider: true, // very important
      providerOptions,
    });
    setWeb3Modal(web3Modal);

    const provider = await web3Modal.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();
    const network = await library.getNetwork();

    const signer = library.getSigner();

    const chainId = await signer.getChainId();
    const binanceTestChainId = "0x38";
    if (chainId === binanceTestChainId) {
      console.log("Bravo!, you are on the correct network");
    } else {
      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x38" }],
        });
        console.log("You have succefully switched to Binance Test network");
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await provider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x38",
                  chainName: "BNB chain",
                  rpcUrls: [process.env.REACT_APP_RPC_URL],
                  blockExplorerUrls: [process.env.REACT_APP_BSCSCAN_EXPLORER],
                  nativeCurrency: {
                    symbol: "BNB",
                    decimals: 18,
                  },
                },
              ],
            });
          } catch (addError) {
            console.log(addError);
          }
        }

        return;
      }
    }

    let account = "";
    if (accounts) {
      account = accounts[0];
      setWeb3Data({ ...INITIAL_STATE, account });
    }
    setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });

    return { provider, library, signer, account, network, chainId };
  };

  const disconnectWallet = async () => {
    await web3Modal.clearCachedProvider();
    setWeb3Data({ ...INITIAL_STATE });
  };

  /** ethers.js utility functions */
  const getAccount = async () => (await web3Data.library?.listAccounts())[0];
  const getChainId = async () => await web3Data.signer.getChainId();
  const getTransactionCount = async () => await web3Data.signer.getTransactionCount();
  const getContract = (contractAddress, abi) => new ethers.Contract(contractAddress, abi, web3Data.signer);
  const getBalance = async () => await web3Data.library?.getBalance(await getAccount());
  const getBlockTimestamp = async () => (await web3Data.library.getBlock("latest")).timestamp;
  const getGasPrice = async () => await web3Data.library.getGasPrice();
  const getFormatEther = (balance) => ethers.utils.formatEther(balance);
  const getFormatUnits = (balance, decimal) => ethers.utils.parseUnits(balance, decimal || "ether");
  const getHashMEssage = (message) => ethers.utils.hashMessage(message);
  const getBignumberFrom = (number) => ethers.BigNumber.from(number);

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connectWallet();
    }
  }, []);

  useEffect(() => {

    const tokenHoldersCount = async () => {
      const options = {
        method: 'POST',
        url: 'https://rpc.ankr.com/multichain/569ca1f392f7d3d0bb1073a1173ecb649af1d9249231fe59fa95db19d5ae41fe/',
        params: { ankr_getTokenHoldersCount: '' },
        headers: { accept: 'application/json', 'content-type': 'application/json' },
        data: {
          jsonrpc: '2.0',
          method: 'ankr_getTokenHoldersCount',
          params: {
            blockchain: 'bsc',
            contractAddress: process.env.REACT_APP_AETERNA_ADDRESS
          },
          id: 1
        }
      };

      const res = await axios.request(options);
      return res.data.result.holderCountHistory[0].holderCount;
    };

    async function getTokenPrice() {
      try {
        setLoadingTokenInfo(true);
        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
        const LP_contract = new ethers.Contract(LP_ADDRESS, Lp_abi, provider);
        const { reserve0, reserve1 } = await LP_contract.getReserves();
        const USD_BNB = await axios.get("https://price.feed.aeterna.eco");
        const _tokenPrice = (reserve1 / 10 ** 18) * USD_BNB.data / (reserve0 / 10 ** process.env.REACT_APP_AETERNA_DECIMAL);
        setTokenPrice(parseFloat(_tokenPrice).toFixed(4));
        const token_contract = new ethers.Contract(process.env.REACT_APP_AETERNA_ADDRESS, token_abi, provider);
        const totalSupply = await token_contract.totalSupply();
        const burnt = await token_contract.balanceOf("0x000000000000000000000000000000000000dEaD");
        const _circulatingSupply = totalSupply - burnt;
        setSupply(parseInt(_circulatingSupply / 10 ** process.env.REACT_APP_AETERNA_DECIMAL));
        setMarketCap(parseInt(totalSupply / 10 ** process.env.REACT_APP_AETERNA_DECIMAL * _tokenPrice));
        const _holders = await tokenHoldersCount();
        setHolders(_holders);
        setLoadingTokenInfo(false);
      } catch (err) {
        console.log(err);
        setLoadingTokenInfo(false);
      }
    }
    getTokenPrice();

  }, []);

  useEffect(() => {
    if (web3Data?.provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log(accounts);
        setWeb3Data({ ...INITIAL_STATE, account: accounts });
      };

      const handleChainChanged = (chainId) => {
        console.log(chainId);
        setWeb3Data({ ...INITIAL_STATE, chainId });
      };

      const handleDisconnect = () => {
        console.log("disconnect");
        disconnectWallet();
      };

      web3Data?.provider.on("accountsChanged", handleAccountsChanged);
      web3Data?.provider.on("chainChanged", handleChainChanged);
      web3Data?.provider.on("disconnect", handleDisconnect);

      return () => {
        if (web3Data?.provider.removeListener) {
          web3Data?.provider.removeListener("accountsChanged", handleAccountsChanged);
          web3Data?.provider.removeListener("chainChanged", handleChainChanged);
          web3Data?.provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [web3Data?.provider]);

  return (
    <web3ModalContext.Provider
      value={{
        INITIAL_STATE,
        web3Modal,
        web3Data,
        tokenPrice,
        supply,
        marketCap,
        holders,
        loadingTokenInfo,
        setWeb3Data,
        connectWallet,
        disconnectWallet,
        getChainId,
        getTransactionCount,
        getContract,
        getBalance,
        getBlockTimestamp,
        getGasPrice,
        getFormatEther,
        getFormatUnits,
        getHashMEssage,
        getBignumberFrom,
      }}
    >
      {children}
    </web3ModalContext.Provider>
  );
}
