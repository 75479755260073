import React, { useState, useContext, useEffect } from "react";
import { CgChevronRightO } from "react-icons/cg";
import { CgChevronLeftO } from "react-icons/cg";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BsDice5 } from "react-icons/bs";
import { TbEraser } from "react-icons/tb";
import Number from "./Number";
import Ticket from "./Ticket";
import { PickNumberContext } from "../../pages/PickTicket";
import { getRandomInt } from "../../utils";
import { numbers } from "../../constant/lottery";
import { v4 as uuid } from "uuid";

export default function NumberPanel() {
  const id = uuid().slice(0, 8);
  const { pickedNum, setPickedNum, ticketCount } = useContext(PickNumberContext);

  const [count, setCount] = useState(0);
  const [pickedNumber, setPickedNumber] = useState([]);
  const [ticketNumber, setTicketNumber] = useState(0);

  const handlePickNum = (num, pickedNumber) => {
    for (let i = 0; i < pickedNumber?.length; i++) {
      if (num == pickedNumber[i]) return true;
    }
    return false;
  };

  const handleNumber = (num) => {
    let list = pickedNumber;
    const index = pickedNumber.indexOf(num);
    if (index > -1) {
      list.splice(index, 1);
      setCount(list.length);
      setPickedNumber(list);
    } else {
      setCount(pickedNumber.length + 1);
      setPickedNumber([...pickedNumber, num]);
    }
  };

  const onPrevTicket = () => {
    if (ticketNumber <= 0) setTicketNumber(0);
    else setTicketNumber(ticketNumber - 1);
  };
  const onNextTicket = () => {
    if (ticketNumber >= ticketCount - 1) setTicketNumber(ticketCount - 1);
    else setTicketNumber(ticketNumber + 1);
  };
  const onSkipAllTicket = () => {
    let random = [];
    let count = 0;
    while (count < 6) {
      const randomInt = getRandomInt(1, 65);
      const index = random.indexOf(randomInt);
      if (index > -1) continue;
      else random.push(randomInt);
      count++;
    }
    setCount(6);
    setPickedNumber(random);
  };
  const onClearAllTicket = () => {
    setCount(0);
    setPickedNumber([]);
  };

  const onSaveTicket = () => {
    if (ticketCount <= pickedNum.length) return;
    const pick = { id: id, pick: [...pickedNumber] };
    let newArr = [...pickedNum, pick];
    setPickedNum([...newArr]);
    onNextTicket();
    setCount(0);
    setPickedNumber([]);
  };

  return (
    <>
      <div className="number-panel">
        <div className="panel-header">
          <div className="flex justify-start items-center">
            <FaRegCalendarAlt style={{ fontSize: "25px", color: "#11bc56", marginRight: "14px" }} />
            <h3>CHOOSE 6 NUMBERS</h3>
          </div>
          <div className="flex justify-end items-center">
            <button className="rounded p-2 mr-2 bg-[#3dff8b]" onClick={onSkipAllTicket}>
              <BsDice5 style={{ fontSize: "17px", color: "#707995" }} />
            </button>
            <button className="rounded p-2 bg-[#5338bc]" onClick={onClearAllTicket}>
              <TbEraser style={{ fontSize: "17px", color: "#707995" }} />
            </button>
          </div>
        </div>
        <div className="panel-body">
          <div className="number-panel">
            <div className="grid grid-cols-10 gap-2">
              {numbers?.map((num, i) => (
                <Number
                  key={i}
                  num={num}
                  count={count}
                  picked={handlePickNum(num, pickedNumber)}
                  final={count > 5 && num === pickedNumber[5]}
                  handleNumber={(pnum) => handleNumber(pnum)}
                />
              ))}
            </div>
          </div>

          <Ticket pickedNumber={pickedNumber} />

          <div className="control">
            <button className="rounded-xl px-1 border border-[#8af0b7]" onClick={onSkipAllTicket}>
              &nbsp; Random &nbsp;
            </button>
            <div className="flex justify-center items-center rounded-xl px-1 border border-[#8af0b7]">
              <button onClick={onPrevTicket}>
                <CgChevronLeftO style={{ fontSize: "16px", color: "fff", marginRight: "20px" }} />
              </button>
              Ticket #{ticketNumber + 1}
              <button onClick={onNextTicket}>
                <CgChevronRightO style={{ fontSize: "16px", color: "fff", marginLeft: "20px" }} />
              </button>
            </div>
            <button className="rounded-xl px-1 border border-[#8af0b7]" onClick={onClearAllTicket}>
              &nbsp; &nbsp; Clear &nbsp; &nbsp;
            </button>
          </div>
          <button className="save-ticket" onClick={onSaveTicket} disabled={count < 6}>
            Save Ticket
          </button>
        </div>
      </div>
    </>
  );
}
