export const SWAP_POSITION = {
  FROM: 0,
  TO: 1,
};

export const MEDIUM_URL = "https://medium.com/@aeternamain/launching-next-month-69d6405e4b25";
export const TWITTER_URL = "https://twitter.com/project_aeterna?s=21&t=r0DWiL42DiO1xpllxh3hOA";
export const DISCORD_URL = "https://discord.gg/projectaeterna";
export const TELEGRAM_URL = "https://t.me/aeternamain";

export const MAX_NUMBER = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
export const BSCSCAN_EXPLORER = "https://bscscan.com";
export const NEGATIVE = 0; // case when is inputB in ETH4TOKEN and TOKEN4ETH
export const POSITIVE = 1; // case when is inputA in ETH4TOKEN and TOKEN4ETH
export const ETH4TOKEN = 2; // ETH4TOKEN
export const TOKEN4ETH = 3; // TOKEN4ETH

export const BSC_Mainnet = 56;
export const BSC_Testnet = 97;
