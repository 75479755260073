import FirstTicketImage from "../assets/images/lottery/first-winning.png";
import NextTicketImage from "../assets/images/lottery/next-winning.png";

export const rule = [
  {
    logo: FirstTicketImage,
    match: "6 NUMBERS JACKPOT BALL",
    prize: "89",
    first: "first",
  },
  { logo: NextTicketImage, match: "5 Numbers", prize: "10" },
  { logo: NextTicketImage, match: "4 Numbers", prize: "1" },
  { logo: NextTicketImage, match: "3 Numbers", prize: "0" },
  { logo: NextTicketImage, match: "2 Numbers", prize: "0" },
  { logo: NextTicketImage, match: "1 Numbers", prize: "0" },
];
