import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { web3ModalContext } from "../Web3ModalProvider";
import UserInfo from "../../assets/images/header/user.png";
import LogoIcon from "../../assets/images/header/logo.png";
import SolidProofIcon from "../../assets/images/header/solid-proof.png";
import Obsidian from "../../assets/images/header/obsidian.png";
import Certik from "../../assets/images/header/certik.png";
import { Oval } from "react-loader-spinner";

export default function Header({ showMobile, handleMobile }) {
  const [showModal, setShowModal] = useState(false);
  const { INITIAL_STATE, web3Modal, web3Data, setWeb3Data, connectWallet, disconnectWallet, tokenPrice, loadingTokenInfo } = useContext(web3ModalContext);

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, connected: true, account, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  const disconnect = async () => {
    try {
      await disconnectWallet();
      setWeb3Data({ ...INITIAL_STATE });
    } catch (err) {
      console.log(err);
    }
  };

  const renderButton = (
    <>
      {web3Data.connected ? (
        <button className="connect-wallet" onClick={disconnect}>
          {web3Data.account.substring(0, 5) + " ... " + web3Data.account.substring(38)}
        </button>
      ) : (
        <button className="connect-wallet" onClick={connect}>
          Connect wallet
        </button>
      )}
    </>
  );

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  const location = useLocation();
  const setShowUserModal = (show) => {
    console.log(showModal);
    setShowModal(show);
  };

  return (
    <header className="header">
      {location.pathname === "/home" ? (
        <div className="header-home-container">
          <div className="lg:container mx-auto px-4 pt-4 pb-2 flex flex-col 2xl:flex-row items-start sm:items-center justify-between">
            <div className="header-home-left px-4 sm:px-0 py-2">
              <img src={LogoIcon} alt="Aeterna Logo" />
              <h3>AETERNA</h3>
            </div>

            <div className="header-home-right flex flex-col xl:flex-row items-start sm:items-center w-full sm:w-auto">
              <a href="https://www.pinksale.finance" className="home-solidproof flex sm:items-center sm:justify-center m-3 w-full sm:w-auto" rel="noreferrer" target="_blank">
                <img src="https://www.pinksale.finance/static/media/pinkswap.a95de4f3.png" alt="Pinksale Finance" className="solidproof ml-5 sm:m-0" />
                <p className="text-white text-[28px] font-bold leading-[40px]">Pinksale</p>
              </a>
              <a href="https://dexview.com" className="home-solidproof flex sm:items-center sm:justify-center m-3 w-full sm:w-auto" rel="noreferrer" target="_blank">
                <img src="https://dexview.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fabb35737-bbc2-482d-a8dd-5e0f0f013c9c%2Fdexview-white-text-transparent.png?id=66a5b7d6-5361-41bd-a09e-6b8565589d77&table=block&spaceId=f2f78fd3-79ff-4ce0-bc9c-ba15ee5eba0f&width=2000&userId=&cache=v2" alt="Dexview" className="solidproof ml-5 sm:m-0" />
              </a>
              <div className="home-solidproof flex sm:items-center sm:justify-center m-3 w-full sm:w-auto">
                <img src={SolidProofIcon} alt="Solid Proof" className="solidproof ml-5 sm:m-0" />
              </div>
              <div className="home-certik flex sm:items-center sm:justify-center m-3 w-full sm:w-auto">
                <img src={Certik} alt="Certik" className="certik ml-5 sm:m-0" />
              </div>
              <Link to="./exchange/swap" className="home-buy-aeterna m-3 w-full sm:w-auto">
                BUY AETERNA
              </Link>
              <Link to="/dashboard" className="home-dashboard-btn m-3 w-full sm:w-auto">
                Dashboard
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="header-container flex flex-wrap items-center justify-between md:justify-end">
            <button
              data-collapse-toggle="navbar-dropdown"
              type="button"
              className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-dropdown"
              aria-expanded="false"
              onClick={() => handleMobile(!showMobile)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <div className="hidden-menu hidden lg:flex flex flex-wrap items-center justify-end">
              <div className="aeterna-price">
                <img src={LogoIcon} alt="Aeterna Logo" />
                {
                  loadingTokenInfo ?
                    <div className="flex justify-center">
                      <Oval
                        height={16}
                        width={16}
                        color="#ffffff"
                        wrapperStyle={{ marginRight: "8px" }}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="transparent"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                      />
                    </div> :
                    <h4>${tokenPrice}</h4>
                }
              </div>
              <img src={SolidProofIcon} alt="Solid Proof" className="solidproof" />
              {/* <img src={Obsidian} alt="Obsidian" className="obsidian" /> */}
              <img src={Certik} alt="Certik" className="certik" />
            </div>
            {renderButton}
            {/* <button className="user-info">
              <img src={UserInfo} />
            </button> */}
          </div>
          {/* <div className="overflow-hidden text-center">
            <button className="user-info-btn" onClick={() => setShowUserModal(true)}>
              ...
            </button>
          </div> */}
        </>
      )}
    </header>
  );
}
