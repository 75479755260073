import { useEffect, useState, useContext } from "react";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import getContractsAddress from "../../web3/contractsAddress";
import PancakeswapFactory from "../../web3/abi/PancakeswapFactory.json";
import PancakeswapRouter from "../../web3/abi/PancakeswapRouter.json";
import { injected } from "../../utils/connector";
import { BiQuestionMark } from "react-icons/bi";
import TokenLogo from "../TokenLogo";
import { web3ModalContext } from "../Web3ModalProvider";
import { SwapSettingContext } from "../SwapSettingProvider";
import SwapIcon from "../../assets/images/dashboard/arrow.png";
import { mainnetTokens } from "../../constant/tokens";
import tokenList from "../../web3/pancakeswap-top-15";
import Lp_abi from "../../web3/abi/Lp_abi.json";
import tokenAbi from "../../web3/abi/tokenAbi.json";
import { notify } from "../../utils";
import { SWAP_POSITION, BSC_Mainnet, BSC_Testnet, MAX_NUMBER } from "../../config";
import timestring from "timestring";
import { Link } from "react-router-dom";

export default function Exchange() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract, getBalance, getFormatUnits, getFormatEther, getBignumberFrom, getBlockTimestamp } = useContext(web3ModalContext);
  const { slippage, timeline } = useContext(SwapSettingContext);
  const routerAddress = getContractsAddress(BSC_Mainnet).PancakeswapRouter;

  const [currency0Address, setCurrency0Address] = useState(mainnetTokens[0].address);
  const [currency1Address, setCurrency1Address] = useState(mainnetTokens[1].address);
  const [currency1, setCurrency1] = useState(null);
  const [currency0, setCurrency0] = useState(null);
  const [currency0Amount, setCurrency0Amount] = useState(0);
  const [currency1Amount, setCurrency1Amount] = useState(0);
  const [swapLoading, setSwapLoading] = useState(false);
  const [currency0Loading, setCurrency0Loading] = useState(false);
  const [currency1Loading, setCurrency1Loading] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const [approveToken, setApproveToken] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [direction, setDirection] = useState(SWAP_POSITION.FROM);

  const initTokens = () => {
    setCurrency0Address(mainnetTokens[0].address);
    setCurrency1Address(mainnetTokens[1].address);
    setCurrency1(null);
    setCurrency0(null);
    setCurrency0Amount(0);
    setCurrency1Amount(0);
  };

  const swapToken = () => {
    setCurrency1Address(currency0Address);
    setCurrency0Address(currency1Address);
    setCurrency0Amount(0);
    setCurrency1Amount(0);
  };

  const getBNBBalance = async () => {
    const balance = await getBalance();
    return balance;
  };

  const getTokenInfo = async (address) => {
    try {
      const tokenContract = getContract(address, tokenAbi);
      const tokenBalance = await tokenContract.balanceOf(web3Data.account);
      const tokenTotalSupply = await tokenContract.totalSupply();
      const tokenSymbol = await tokenContract.symbol();
      const tokenName = await tokenContract.name();
      const tokenDecimals = await tokenContract.decimals();
      const tokenAllowance = await tokenContract.allowance(web3Data.account, routerAddress);
      let tokenLogo = undefined;

      if (web3Data.chainId === BSC_Mainnet) {
        const token = mainnetTokens.filter((token) => token.address === address);
        tokenLogo = token[0].logoURI;
      } else if (web3Data.chainId === BSC_Testnet) {
        const token = mainnetTokens.filter((token) => token.address === address);
        tokenLogo = token[0].logoURI;
      }

      return {
        contract: tokenContract,
        address: address,
        name: tokenName,
        symbol: tokenSymbol,
        balance: tokenBalance,
        decimals: tokenDecimals,
        totalSupply: tokenTotalSupply,
        allowance: tokenAllowance,
        logoURI: tokenLogo,
      };
    } catch (err) {
      console.log("getTokenInfo is failed for " + address + ".\n", err);
    }
  };

  const getToken = async (address, pos) => {
    pos === SWAP_POSITION.FROM ? setCurrency0Loading(true) : setCurrency1Loading(true);
    try {
      if (address === mainnetTokens[0].address) {
        // BNB
        const balance = await getBNBBalance();
        const currency = {
          name: mainnetTokens[0].name,
          symbol: mainnetTokens[0].symbol,
          balance: balance,
          decimals: mainnetTokens[0].decimals,
          address: mainnetTokens[0].address,
          totalSupply: undefined,
          logoURI: mainnetTokens[0].logoURI,
        };
        pos === SWAP_POSITION.FROM ? setCurrency0(currency) : setCurrency1(currency);
      } else {
        // Token
        const currency = await getTokenInfo(address);
        pos === SWAP_POSITION.FROM ? setCurrency0(currency) : setCurrency1(currency);
      }
    } catch (err) {
      console.log(`getToken by ${pos === SWAP_POSITION.FROM ? "FROM" : "TO"} is failed.\n`, err);
      pos === SWAP_POSITION.FROM ? setCurrency0Loading(false) : setCurrency1Loading(false);
    }
    pos === SWAP_POSITION.FROM ? setCurrency0Loading(false) : setCurrency1Loading(false);
  };

  const getTokens = async () => {
    try {
      if (currency0Address !== "") {
        await getToken(currency0Address, SWAP_POSITION.FROM);
      }
      if (currency1Address !== "") {
        await getToken(currency1Address, SWAP_POSITION.TO);
      }
    } catch (err) {
      console.log("getTokens is failed.\n", err);
    }
  };

  const swapETH4Aeterna = async (contract, path, amount0, amount1, deadline) => {
    let result = {};
    const timestamp = await getBlockTimestamp() + timestring(deadline + 'm');

    if (direction === SWAP_POSITION.FROM) {
      const amount1Min = await handle0Amount(amount0, 18, 9); // token2ETH = positive
      result = await contract.swapExactETHForTokensSupportingFeeOnTransferTokens(
        // complete
        getBignumberFrom(amount1Min)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp,
        { value: getFormatUnits(parseFloat(amount0).toFixed(18).toString()) }
      );
    } else if (direction === SWAP_POSITION.TO) {
      // complete
      const amount0Max = await handle1Amount(amount1, 9, 18);
      result = await contract.swapETHForExactTokens(
        getFormatUnits(amount1).div(10 ** 9),
        path,
        web3Data.account,
        timestamp,
        { value: parseInt(amount0Max * (1 + slippage / 100)).toString() }
      );
    }
    await result.wait();
    return result;
  };

  const swapAeterna4ETH = async (contract, path, amount0, amount1, deadline) => {
    try {
      let result = {};
      const timestamp = await getBlockTimestamp() + timestring(deadline + 'm');

      if (direction === SWAP_POSITION.FROM) {
        const amount1Min = await handle0Amount(amount0, 9, 18);
        result = await contract.swapExactTokensForETHSupportingFeeOnTransferTokens(
          // complete
          getFormatUnits(amount0).div(10 ** 9),
          (parseInt(amount1Min * (1 - slippage / 100))).toString(),
          path,
          web3Data.account,
          timestamp
        );
      } else if (direction === SWAP_POSITION.TO) {
        // complete
        const amount0Max = await handle1Amount(amount1, 18, 9);
        result = await contract.swapTokensForExactETH(
          getFormatUnits(amount1).toString(),
          (parseInt(amount0Max * (1 + slippage / 100)).toString()),
          path,
          web3Data.account,
          timestamp
        );
      }
      await result.wait();
      return result;
    } catch (err) {
      console.log(err);
      if (err.message.indexOf("Pancake: K") > 0) {
        toast.error("Unknown error: \"\Execustion reverted with reason: Insufficient WETH9.\"\.\n Try increasing your slippage tolerance.");
      }
    }
  };

  const handleApprove = async () => {
    try {
      if (approveToken.length <= 0) {
        toast.error("approveToken is empty.");
        return;
      } else if (approveToken.length > 0) {
        setApproveLoading(true);
        for (let i = approveToken.length; i > 0; i--) {
          const approve = await approveToken[i - 1].contract.approve(routerAddress, MAX_NUMBER);
          await approve.wait();
          const symbol = approveToken[i - 1].symbol;
          const remoeToken = approveToken.pop();
          setApproveToken(remoeToken);
          notify({
            text: "Approve " + symbol,
            link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${approve.hash}`,
          });
        }
        setApproveLoading(false);
      }
    } catch (err) {
      console.log(err);
      notify({
        text: "Approve " + approveToken[0]?.symbol + " " + approveToken[0]?.symbol + "is failed.",
      });
      setApproveLoading(false);
    }
  };

  const handleSwap = async () => {
    setSwapLoading(true);
    try {
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      let result = {};

      if (currency0Address === mainnetTokens[0].address) {
        const path = [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address];
        result = await swapETH4Aeterna(routerContract, path, currency0Amount, currency1Amount, timeline);
      } else if (currency1Address === mainnetTokens[0].address) {
        const path = [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress];
        result = await swapAeterna4ETH(routerContract, path, currency0Amount, currency1Amount, timeline);
      }

      setCurrency0Amount(0);
      setCurrency1Amount(0);
      notify({
        text: `Swap ${currency0Amount} ${currency0.symbol} for ${currency1Amount} ${currency1.symbol}`,
        link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${result.hash}`,
      });

      const timer = setTimeout(() => {
        getTokens();
      }, 4000);

      setSwapLoading(false);
    } catch (err) {
      setSwapLoading(false);
      console.log("handleSwap is failed.\n", err);
    }
  };

  // @param amount: input value
  // @param inDec: input's decimals
  // @param outDec: output's decimals
  // @returns aount1min: minimum amount
  const handle0Amount = async (amount, inDec, outDec) => {
    try {
      let amount1Min = 0;
      const routerContract = getContract(routerAddress, PancakeswapRouter);
      console.log(currency1Address);
      if (amount !== undefined || amount !== "" || amount > 0) {
        setDirection(SWAP_POSITION.FROM);
        setCurrency0Amount(amount);

        const path =
          currency0Address === mainnetTokens[0].address
            ? [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address]
            : currency1Address === mainnetTokens[0].address
              ? [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress]
              : [currency0Address, currency1Address];
        amount1Min = (await routerContract.getAmountsOut((amount * (10 ** inDec)).toString(), path))[1];
        if ((amount * 10 ** inDec) > currency0?.balance) setOverflow(true)
        else setOverflow(false);
        setCurrency1Amount(parseFloat(amount1Min.toString() / 10 ** outDec).toFixed(6));
        if ((amount * (10 ** inDec)) > currency0?.allowance && currency0Address !== mainnetTokens[0].address) {
          const token = approveToken.length > 0 ? approveToken?.filter((token) => token.symbol !== currency0?.symbol) : [];
          setApproveToken([...token, currency0]);
        } else setApproveToken([]);

        return amount1Min;
      }
    } catch (err) {
      console.log("handle0Amount is failed.\n", err);
    }
  };

  const handle1Amount = async (amount, inDec, outDec) => {
    try {
      let amount0 = 0;
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      if (amount !== undefined || amount !== "" || amount > 0) {
        setDirection(SWAP_POSITION.TO);
        setCurrency1Amount(amount);

        const path =
          currency0Address === mainnetTokens[0].address
            ? [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address]
            : currency1Address === mainnetTokens[0].address
              ? [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress]
              : [currency0Address, currency1Address];
        amount0 = (await routerContract.getAmountsIn((amount * (10 ** inDec)).toString(), path))[0];
        if (parseFloat(amount0) > parseFloat(currency0?.balance)) setOverflow(true)
        else setOverflow(false);
        setCurrency0Amount(parseFloat(amount0.toString() / (10 ** outDec)).toFixed(6));
        if (amount0 > currency0?.allowance && currency0Address !== mainnetTokens[0].address) {
          const token = approveToken.length > 0 ? approveToken?.filter((token) => token.symbol !== currency0?.symbol) : [];
          setApproveToken([...token, currency0]);
        } else setApproveToken([]);

        return amount0;
      }
    } catch (err) {
      console.log("handle1Amount is failed.\n", err);
    }
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getTokens();
    } else {
      initTokens();
    }
  }, [web3Data.connected, currency0Address, currency1Address]);

  return (
    <>
      <div className="exchange">
        <div className="exchange-pay">
          <div className="pay-left">
            <h3>You pay</h3>
            <form>
              <input
                name="payValue"
                type="number"
                placeholder="0.00"
                min={0}
                value={currency0Amount}
                onChange={(e) => {
                  if ((e.target.value * (10 ** currency0?.decimals)) > (currency0?.allowance)) {
                    const token = approveToken.length > 0 ? approveToken?.filter((token) => token.symbol !== currency0?.symbol) : [];
                    console.log(token, approveToken);
                    setApproveToken([...token, currency0]);
                  } else setApproveToken([]);
                  currency0Address === mainnetTokens[0].address ? handle0Amount(e.target.value, 18, 9) : handle0Amount(e.target.value, 9, 18)
                }}
              />
            </form>
            <h4>~$0.00</h4>
          </div>
          <div className="pay-right">
            <button className="pay-crypto">
              <TokenLogo width="24px" height="24px">
                {currency0?.logoURI === undefined ? (
                  <BiQuestionMark style={{ fontSize: "30px" }} />
                ) : (
                  <img src={currency0?.logoURI} alt={currency0?.symbol} />
                )}
              </TokenLogo>
              <p>{currency0?.symbol}</p>
            </button>
            <h4>
              Balance:{" "}
              {currency0 ? parseFloat(currency0?.balance?.toString() / 10 ** currency0?.decimals).toFixed(2) : parseFloat(0).toFixed(2)}
            </h4>
          </div>
          <button className="exchange-button" onClick={() => swapToken()}>
            <img src={SwapIcon} alt="Swap icon" />
          </button>
        </div>
        <div className="exchange-get">
          <div className="get-left">
            <h3>You get</h3>
            <form>
              <input
                name="payValue"
                type="number"
                placeholder="0.00"
                min={0}
                value={currency1Amount}
                onChange={(e) => {
                  if ((e.target.value * (10 ** currency1?.decimals)) > (currency1?.allowance)) {
                    const token = approveToken.length > 0 ? approveToken?.filter((token) => token.symbol !== currency1?.symbol) : [];
                    console.log(token, approveToken);
                    setApproveToken([...token, currency1]);
                  } else setApproveToken([]);
                  currency0Address === mainnetTokens[0].address ? handle1Amount(e.target.value, 9, 18) : handle1Amount(e.target.value, 18, 9);
                }}
              />
            </form>
            <h4>~$0.00</h4>
          </div>
          <div className="get-right">
            <button className="get-crypto">
              <TokenLogo width="24px" height="24px">
                {currency1?.logoURI === undefined ? (
                  <BiQuestionMark style={{ fontSize: "30px" }} />
                ) : (
                  <img src={currency1?.logoURI} alt={currency1?.symbol} />
                )}
              </TokenLogo>
              <p>{currency1?.symbol}</p>
            </button>
            <h4>
              Balance:{" "}
              {currency1 ? parseFloat(currency1?.balance?.toString() / 10 ** currency1?.decimals).toFixed(2) : parseFloat(0).toFixed(2)}
            </h4>
          </div>
        </div>
      </div>
      {web3Data.connected ?
        overflow ?
          <button
            className="connect-wallet"
            disabled={overflow}
          >
            Insufficient Balance
          </button> : approveToken.length > 0 ? (
            <button className="connect-wallet" disabled={approveLoading} onClick={handleApprove}>
              {
                approveLoading &&
                <Oval
                  height={16}
                  width={16}
                  color="#ffffff"
                  wrapperStyle={{ marginRight: "8px" }}
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="transparent"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              }
              Enable {approveToken[0]?.symbol} {approveToken[1]?.symbol}
            </button>
          ) : (
            <button
              className="connect-wallet"
              disabled={swapLoading || currency0Amount <= 0 || currency1Amount <= 0}
              onClick={handleSwap}
            >
              {swapLoading && (
                <Oval
                  height={16}
                  width={16}
                  color="#ffffff"
                  wrapperStyle={{ marginRight: "8px" }}
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="transparent"
                  strokeWidth={4}
                  strokeWidthSecondary={4}
                />
              )}
              Confirm swap
            </button>
          ) : (
          <button className="connect-wallet" onClick={connect}>
            Connect your wallet
          </button>
        )}
      {/* <div className="share-earn">Share & Earn</div>
      <div className="chart">Chart</div>
      <div className="buy-crypto">Buy Crypto</div> */}
    </>
  );
}
