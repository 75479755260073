import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import nftContractAbi from "../assets/Abis/escrow-abi.json";
import Layout from "../components/Escrow/Layout";
import NavBar from "../components/Escrow/NavBar";
import { genRandomChannelId, postNewBulletin, loadBulletinLists } from "../utils/utils";

export default function EscrowPostBulletin() {
  const params = useParams();
  const bulletinId = params?.bulletinId;
  console.log(bulletinId);
  const [image, setImage] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [selectedType, setSelectedType] = useState("product");
  const [chatRoomNumberValue, setChatRoomNumberValue] = useState("");
  const [bulletinTitleValue, setBulletinTitleValue] = useState("");
  const [bulletinTextValue, setBulletinTextValue] = useState("");
  const [businessInfoValue, setBusinessInfoValue] = useState("");
  const [isWalletIntalled, setIsWalletInstalled] = useState(false);

  const { chainId, active } = useWeb3React();
  const validNetwork = chainId === parseInt(process.env.REACT_APP_CHAIN_ID) ? true : false;

  let myProvider, EscrowContract;
  if (window.ethereum) {
    myProvider = new ethers.providers.Web3Provider(window.ethereum);
    // eslint-disable-next-line no-unused-vars
    EscrowContract = new ethers.Contract(process.env.REACT_APP_ESCROW_ADDRESS, nftContractAbi, myProvider.getSigner());
  }
  useEffect(() => {
    if (window.ethereum) {
      setIsWalletInstalled(true);
    }
    if (bulletinId)
      loadBulletinLists(bulletinId).then((bulletins) => {
        console.log(bulletins);
        setSelectedType(bulletins[0].type);
        setChatRoomNumberValue(bulletins[0].room_no);
        setBulletinTitleValue(bulletins[0].title);
        setBulletinTextValue(bulletins[0].content);
        setBusinessInfoValue(bulletins[0].business_info);
      });
  }, []);

  useEffect(() => {
    if (validNetwork && active && window.ethereum) {
      const myProvider = new ethers.providers.Web3Provider(window.ethereum);
      EscrowContract = new ethers.Contract(
        process.env.REACT_APP_ESCROW_ADDRESS,
        nftContractAbi,
        myProvider.getSigner()
      );
    }
  }, [validNetwork, active]);

  const createNewChatRoom = async () => {
    const uuid = genRandomChannelId();
    window.open("/escrow/chat/" + uuid, "_blank");
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageBlob(event.target.files[0]);
      console.log(event.target.files[0]);
    }
  };

  const handleChangeType = (evt) => {
    setSelectedType(evt.target.value);
  };

  const handleChatRoomNumberValueChange = (event) => {
    setChatRoomNumberValue(event.target.value);
  };

  const handleBulletinTitleChange = (event) => {
    setBulletinTitleValue(event.target.value);
  };

  const handleBulletinTextChange = (event) => {
    setBulletinTextValue(event.target.value);
  };

  const handleBusinessInfoChange = (event) => {
    setBusinessInfoValue(event.target.value);
  };

  const handleSubmitBulletin = async () => {
    const bulletin = {
      selectedType,
      chatRoomNumberValue,
      bulletinTitleValue,
      bulletinTextValue,
      businessInfoValue,
      imageBlob,
    };
    console.log(bulletin)
    postNewBulletin(bulletin);
    toast.success("successfully submitted");
  };

  return (
    <Layout>
      <NavBar></NavBar>
      <Stack alignItems="center"></Stack>
      <div className="product-wrapper text-white">
        <h2>Post public bulletin</h2>
        <h4>I am the seller</h4>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          className="justify-content-space-evenly"
          onChange={handleChangeType}
          value={selectedType}
        >
          <FormControlLabel value="product" control={<Radio />} label="Product" />
          <FormControlLabel value="service" control={<Radio />} label="Service" />
        </RadioGroup>
        {isWalletIntalled ? (
          <Stack className="product-input-wrapper">
            <Stack>
              <Button variant="contained" className="create-chat-btn" onClick={() => createNewChatRoom()}>
                Create a new chat room
              </Button>
            </Stack>
            <div className="input-area">
              <input
                placeholder="Enter Chat Room ID"
                value={chatRoomNumberValue}
                onChange={handleChatRoomNumberValueChange}
                disabled={bulletinId}
              />
            </div>
            <div className="input-area">
              <input
                placeholder="Enter Bulletin Title"
                value={bulletinTitleValue}
                onChange={handleBulletinTitleChange}
                disabled={bulletinId}
              />
            </div>
            <div className="input-area">
              <textarea
                placeholder="Enter Bulletin Texts (120 max characters)"
                maxLength="120"
                value={bulletinTextValue}
                onChange={handleBulletinTextChange}
                disabled={bulletinId}
              />
            </div>
            <div className="input-area">
              <textarea
                placeholder="Enter Provider’s business info (2000 max characters)"
                maxLength="2000"
                value={businessInfoValue}
                onChange={handleBusinessInfoChange}
                disabled={bulletinId}
              />
            </div>
            <div className="screenshot-area-wrapper">
              <input type="file" onChange={onImageChange} className="filetype" />
              <img src={image} alt="preview image" className="width-fill-available" />
            </div>
            <Stack>
              <Button
                variant="contained"
                className="seller-product-submit-btn"
                onClick={() => handleSubmitBulletin()}
                disabled={
                  !chatRoomNumberValue || !bulletinTitleValue || !bulletinTextValue || !businessInfoValue || !image
                }
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        ) : (
          <h1>Install the wallet first</h1>
        )}
      </div>
    </Layout>
  );
}
