import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import CircleNumber from "./CircleNumber";
import moment from "moment";
import { getFormatEther } from "../../web3/getEthers";
import { getLotteryContract } from "../../web3/getLotteryContract";
import { notify } from "../../utils";

export default function DrawCard({ lottery }) {
  const navigate = useNavigate();
  const lotteryContract = getLotteryContract();
  const [isClaim, setIsClaim] = useState(false);
  const [winningTicketsId, setWinningTicketsId] = useState([]);

  const isClaimLottery = (data) => {
    let temp = [];
    if (data.tickets.length > 0 && data.ticketsStats.length > 0) {
      for (let i = 0; i < data.ticketsStats.length; i++)
        if (data.ticketsStats[i] === true) {
          temp.push(data.ticketsId[i]);
          setIsClaim(true);
        } else {
          setIsClaim(false);
        }
    }
    setWinningTicketsId(temp);
  };

  const handleClaim = async (lotteryId, ticketsId) => {
    const result = await lotteryContract.claimTickets(lotteryId, ticketsId);
    notify({
      text: "Claimed " + "5 AETERNA",
      link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${result.hash}`,
    });
  };

  useEffect(() => {
    console.log(lottery);
    isClaimLottery(lottery);
  }, []);

  return (
    <div
      className="draw-card"
      onClick={() =>
        navigate(`/lottery/${lottery.id}/current/ticket`, {
          state: { tickets: lottery.tickets },
        })
      }
    >
      <p className="round">
        Round <span>{lottery.id}</span>
      </p>
      <div className="draw-body">
        <div className="datetime">
          <h6 className="date">
            <Moment format="MMM D, YYYY">{lottery.startTime * 1000}</Moment>
          </h6>
          <p className="time">{moment.utc(lottery.startTime * 1000).format("hh:mm")} UTC</p>
        </div>
        <h3 className="card-number">{parseFloat((getFormatEther(lottery.amountCollected)*0.6).toFixed(2))} BNB</h3>
        <div className="draw-number">
          <CircleNumber number={lottery.finalNumber.numbers[0].toString()} />
          <CircleNumber number={lottery.finalNumber.numbers[1].toString()} />
          <CircleNumber number={lottery.finalNumber.numbers[2].toString()} />
          <CircleNumber number={lottery.finalNumber.numbers[3].toString()} />
          <CircleNumber number={lottery.finalNumber.numbers[4].toString()} />
          <CircleNumber number={lottery.finalNumber.numbers[5].toString()} green={true} />
        </div>
        <div className="flex flex-row justify-between w-full my-2">
          <p className="ml-4">DRAW #</p>
          <p className="mr-4">{lottery.id}</p>
        </div>
        <div className="flex flex-row justify-between w-full my-2">
          <p className="ml-4">TICKETS SOLD</p>
          <p className="mr-4">{lottery.ticketCount}</p>
        </div>
        {/* <div className="flex flex-row justify-between w-full my-2">
          <p className="ml-4">WINNERS</p>
          <p className="mr-4">{0}</p>
        </div> */}
        {isClaim && (
          <>
            <div className="winner-text m-2">
              <p>!YOU ARE WINNERS!</p>
            </div>
            <button
              className="claim-button my-3"
              onClick={async () => await handleClaim(lottery?.id, winningTicketsId)}
            >
              Claim
            </button>
          </>
        )}
      </div>
    </div>
  );
}
